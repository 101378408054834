import downloadJsonFile from './../../utils/downloadJsonFile';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import EventService from "../../services/EventService";
import { AppThunk } from "../store";
import { ColumnInterface, FieldInfo } from '../../helpers/Interfaces';
import { toast } from 'react-toastify';

interface StateInterface {
  data: ColumnInterface[] | any; //?
  checkedBoxes: boolean[];
  fixedCheckbox: boolean;
  dropdownsToChangeBecauseOfCheckbox: FieldInfo[];
  shouldGenerateRandom: boolean;
  journeyBaseId: string;
  generateButtonDisabled: boolean;
}

let initialState: StateInterface = {
  data: [],
  checkedBoxes: [],
  fixedCheckbox: false,
  dropdownsToChangeBecauseOfCheckbox: [],
  shouldGenerateRandom: false,
  journeyBaseId: '',
  generateButtonDisabled: false,
};

const columnsDetails = createSlice({
  name: "columns",
  initialState,
  reducers: {
    createInitialStructureSuccess(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    modifySingleElement(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    createCheckboxesArray(state, action: PayloadAction<any>) {
      state.checkedBoxes = action.payload;
    },
    modifyCheckboxesArray(state, action: PayloadAction<any>) {
      state.checkedBoxes = action.payload;
    },
    modifyFixedCheckbox(state, action: PayloadAction<any>) {
      state.fixedCheckbox = action.payload;
    },
    changeCheckedDropdownsValues(state, action: PayloadAction<any>) {
      state.dropdownsToChangeBecauseOfCheckbox = action.payload;
    },
    toggleRandomGenerateValues(state, action: PayloadAction<any>) {
      state.shouldGenerateRandom = action.payload;
    },
    setJourneyBaseId(state, action: PayloadAction<any>) {
      state.journeyBaseId = action.payload;
    },
    setGenerateButtonStatus(state, action: PayloadAction<any>) {
      state.generateButtonDisabled = action.payload;
    }
  },
});

export const {
  createInitialStructureSuccess,
  modifySingleElement,
  createCheckboxesArray,
  modifyCheckboxesArray,
  modifyFixedCheckbox,
  changeCheckedDropdownsValues,
  toggleRandomGenerateValues,
  setJourneyBaseId,
  setGenerateButtonStatus,
} = columnsDetails.actions;

export default columnsDetails.reducer;

const initialColumn = {
  GPS: "GOOD",
  SPEED: "SLOW",
  MOTION_DETECTION: "IN_VEHICLE",
  GEO_IN: false,
  GEO_OUT: false,
  SWIPE_IN: false,
  SWIPE_OUT: false,
};

export const initialGenerateStructureAction = (
  amountOfColumns: number,
  caseToUse?: any[],
): AppThunk => async (dispatch) => {
  let initialData;
  if(caseToUse) {
    initialData = caseToUse;
  } else {
    initialData = [];
    for (let i = 0; i < amountOfColumns; i++) {
      initialData.push({ ...initialColumn });
    }
  }
  dispatch(createInitialStructureSuccess(initialData));
};

export const changeSelectedValueAction = (
  columnIndex: number,
  propertyName: string,
  newValue: string | boolean
): AppThunk => async (dispatch, getState) => {
  const originalStructure = getState().columns.data;
  const copyOfStructure = [...originalStructure];
  const editedObject = copyOfStructure[columnIndex];
  const newVersion = {
    ...editedObject,
    [propertyName]: newValue,
  };
  copyOfStructure[columnIndex] = newVersion;  
  dispatch(modifySingleElement(copyOfStructure));
};

export const updateColumnValuesAction = (
  columnIndex: number,
  newColumnValues: any,
): AppThunk => async (dispatch, getState) => {
  const originalStructure = getState().columns.data;
  const copyOfStructure = [...originalStructure];
  copyOfStructure[columnIndex] = newColumnValues;
  dispatch(modifySingleElement(copyOfStructure));
}

export const sendEventsToBackendAction = (
  events: unknown[],
  creator: string | null,
  caseId: string | null,
  assumedPrice: number,
  transferStations: any[],
): AppThunk => async (dispatch) => {
  try {
    const body = { events, creator, caseId, assumedPrice, transferStations };
    const response = await EventService.getTripParsedEvents(body);
    downloadJsonFile(response.data)
    toast.success('Success!');
    dispatch(setGenerateButtonStatusAction(true));
  } catch (error) {
    toast.error(error.response ? error.response.data.message : "Error");
  }
};

export const initialGenerateCheckboxesArrayAction = (
  amountOfColumns: number
): AppThunk => async (dispatch) => {
  const initialData = [];
  for (let i = 0; i <= amountOfColumns; i++) {
    initialData.push(false);
  }
  dispatch(createCheckboxesArray(initialData));
};

export const changeCheckedAction = (
  columnIndex: any,
): AppThunk => async (dispatch, getState) => {
  const originalArray = getState().columns.checkedBoxes;
  const copyOfArray = [...originalArray];
  if( columnIndex === 'fixed') {
    const fixedCheckboxValue = getState().columns.fixedCheckbox;
    dispatch(modifyFixedCheckbox(!fixedCheckboxValue));
    copyOfArray.fill(!fixedCheckboxValue)
  } else  {
    const newValue = !copyOfArray[columnIndex];
    copyOfArray[columnIndex] = newValue;
  }
  dispatch(modifyCheckboxesArray(copyOfArray))
};

export const dropdownsChangedByCheckboxAction = (
  array: FieldInfo[]
): AppThunk => async (dispatch) => {
    dispatch(changeCheckedDropdownsValues(array));
};

export const changeRandomGenerateAction = (): AppThunk => async (dispatch, getState) => {
    const actualStatus = getState().columns.shouldGenerateRandom;
    dispatch(toggleRandomGenerateValues(!actualStatus));
};

export const sendCaseToBackendAction = (
  journeyBaseId: string | null,
  caseName: string,
  caseType: string,
  journeyData: any[],
  loggedUser: string,
  givenCaseId?: string,
): AppThunk => async () => {
  let savedCaseId;
  try {
    if (givenCaseId) {
      const body = {caseName, caseType, journeyData, caseId: givenCaseId };  
      const resp = await EventService.getTripParsedCaseToEdit(body);
      savedCaseId = resp.data._id;
    } else {
      const body = { journeyBaseId, caseName, caseType, journeyData, loggedUser };
      const resp = await EventService.getTripParsedCaseToSave(body);
      savedCaseId = resp.data._id;
    }
    toast.success('Saved!');
    return savedCaseId;
  } catch (error) {
    toast.error(error.response ? error.response.data.message : "Error");
  }
};

export const setJourneyBaseIdAction = (
  journeyBaseId: string
): AppThunk => async(dispatch) => {
  dispatch(setJourneyBaseId(journeyBaseId));
}

export const setGenerateButtonStatusAction = (status: boolean): AppThunk => async(dispatch) => {
  dispatch(setGenerateButtonStatus(status));
}