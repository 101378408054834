import { APIService } from "./ApiService";

const getTripParsedEvents = ({
  events,
  creator,
  caseId,
  assumedPrice,
  transferStations,
}) =>
  APIService.post(`/events/users/${creator}`, {
    events,
    caseId,
    assumedPrice,
    transferStations,
  });
const getTripParsedCaseToSave = ({
  journeyBaseId,
  caseName,
  caseType,
  journeyData,
  loggedUser,
}) =>
  APIService.post(`/cases/users/${loggedUser}/journeys/${journeyBaseId}`, {
    caseName,
    caseType,
    journeyData,
  });

const getTripParsedCaseToEdit = ({ caseName, caseType, journeyData, caseId }) =>
  APIService.put(`/cases/${caseId}`, {
    caseName,
    caseType,
    journeyData,
  });

export default {
  getTripParsedEvents,
  getTripParsedCaseToSave,
  getTripParsedCaseToEdit,
};
