import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { AssumedHistoryBlockProps } from '../../../helpers/Interfaces';
import { weatherRender } from '../../../utils'
import { Button } from '../../../helpers/PrimereactComponents';
import { APIService } from '../../../services/ApiService';

const HistoryContainer = styled.div<{ top: number, height: number, selected: boolean, ratedBad: boolean | null }>`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  left: 0;
  transform: translate(50px, -100%);
  width: 700px;
  display: grid;
  grid-template-columns: ${({ ratedBad }) => ratedBad ? '50px 50px 1fr 35px 0.4fr' : '50px 50px 1fr 35px'} ;
  overflow: visible;

  ::after {
    position: absolute;
    left: 47px;
    top: -3px;
    content: '';
    width: 656px;
    height: ${({ height }) => `${height + 6}px`};
    background: ${({ theme }) => theme.primary};
    transition: 0.4s ease-in-out 0.1s;
    transform-origin: left center;
    transform: ${({ selected }) => selected ? 'scale(1, 1)' : 'scale(0, 1)'};
  }

  > div {
    display: flex;
    flex-direction: ${({ height }) => height > 56 ? 'column' : 'row'}; 
    justify-content: ${({ height }) => height > 56 ? 'center' : 'space-around'};
    align-items: center;
    height: 100%;
    margin-top: ${({ height }) => height < 16 ? `-${(16 - height) / 2}px` : '0px'};
    transition: all .2s ease-in;
  }
`;

const CaseContainer = styled.div<{ size: number }>`
  font-size: ${({ size }) => `${size > 66 ? 50 : size / 2}px`};
`;

const HistoryPrice = styled.div<{ selected: boolean }>`
  background: ${({ selected, theme }) => selected ? "#61b3ff" : theme.white50};
  z-index: 3;
`;

const HistoryContent = styled.div<{ selected: boolean, rated: boolean | null }>`
  background: ${({ selected, theme }) => selected ? "#61b3ff" : theme.white50};
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.59);
  z-index: 2;

  ${({ rated }) => rated === false && css`
    box-shadow: 0px 0px 18px 0px rgba(0, 150, 0, 0.59);
  `};

  ${({ rated }) => rated && css`
    box-shadow: 0px 0px 18px 0px rgba(255, 0, 0, 0.59);
  `};
`;

const RateButtonsContainer = styled.div<{ selected: boolean }>`
  height: 100%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ selected, theme }) => selected ? "#61b3ff" : theme.white50};
`;

const RateButton = styled.button<{ ratedPositive?: boolean | null, ratedNegative?: boolean | null, height: number }>`
  outline: none;
  color: ${({ theme }) => theme.black100};
  background: transparent;

  i {
    font-size: ${({ height }) => height > 20 ? '20px' : `${height}px`};
    ${({ ratedPositive }) => ratedPositive && css`

      color: #00ff00;
      font-weight: bold;
    `};

    ${({ ratedNegative }) => ratedNegative && css`
      color: #d10101;
      font-weight: bold;
    `};
  }
`;

const StyledComment = styled.textarea`
  height: 100%;
  z-index: 4;
  resize: none;
  font-size: 14px;
`;

const SaveButton = styled(Button) <{ height: number }>`
  position: absolute;
  right: ${({ height }) => height > 10 ? '5px' : 0};
  bottom: ${({ height }) => height > 10 ? '5px' : 0};
  padding: 2px;
  font-size: 14px;
  z-index: 4;
  font-size: 14px;

  background: rgba(33, 150, 243, 0.4);
  transition: 0.3s ease-in-out;

  :hover {
    background: rgba(33, 150, 243, 1);
  }
`;

const AssumedHistoryBlock: React.FC<AssumedHistoryBlockProps> = ({ top, height, pixelRatio, dataObject, handleClick, setDataToSummary, selected }) => {
  const { caseType, assumedCost, firstEvent, lastEvent, transferStations, status } = dataObject;
  const [ratedBad, setRatedBad] = useState<boolean | null>(convertStringIntoBool(status?.eventStatus));
  const [commentValue, setCommentValue] = useState<string>(status?.failureNote || "");
  const [isTextareaFocused, setIsTextareaFocused] = useState<boolean>(false);

  function convertStringIntoBool(value: string) {
    switch (value) {
      case "SUCCESS":
        return false;
      case "FAILURE":
        return true;
      case "UNKNOWN":
        return null;
      default:
        return null;
    }
  }

  const formatTimestamp = (date: string, format: string) => moment(date).format(format)

  const rateTrip = async (rate: boolean) => {
    setRatedBad(rate);
    const { rawEventId } = status;
    const body = {
      versionId: "",
      rawEventId,
      eventStatus: !rate,
    }
    await APIService.post('/history/statuses', body);
    const newSummaryData = await APIService.get('/history/statuses').then((response) => response.data);
    setDataToSummary(newSummaryData);
  }

  const sendComment = () => {
    const { rawEventId } = status;
    const body = {
      versionId: "",
      rawEventId,
      eventStatus: false,
      failureNote: commentValue,
    }
    APIService.post('/history/statuses', body);
  }

  return (
    <HistoryContainer
      top={top * pixelRatio}
      height={height * pixelRatio}
      selected={selected}
      ratedBad={ratedBad}
    >
      <CaseContainer size={height * pixelRatio}>
        {weatherRender(caseType)}
      </CaseContainer>
      <HistoryPrice selected={selected} >
        {assumedCost ? (assumedCost / 100).toFixed(2) : 0}€
      </HistoryPrice>
      <HistoryContent selected={selected} rated={ratedBad} onClick={handleClick}>
        <p>{firstEvent.stationName} - {transferStations.map(({ name }: any) => `${name} - `)}{lastEvent.stationName}</p>
        <p>{formatTimestamp(firstEvent.timestamp, "HH:mm")} - {transferStations.map(({ arrivalDateTime }: any) => `${formatTimestamp(arrivalDateTime, "HH:mm")} - `)}{formatTimestamp(lastEvent.timestamp, "HH:mm")}</p>
      </HistoryContent>
      <RateButtonsContainer selected={selected}>
        <RateButton height={height} ratedPositive={ratedBad === false} onClick={() => rateTrip(false)} ><i className="pi pi-check" /></RateButton>
        <RateButton height={height} ratedNegative={ratedBad} onClick={() => rateTrip(true)}><i className="pi pi-times" /></RateButton>
      </RateButtonsContainer>
      {ratedBad && <StyledComment value={commentValue} onChange={(e: any) => setCommentValue(e.target.value)} onFocus={() => setIsTextareaFocused(true)} onBlur={() => setTimeout(() => { setIsTextareaFocused(false) }, 200)} />}
      {isTextareaFocused && <SaveButton height={height} onClick={() => sendComment()} label="save" />}
    </HistoryContainer>
  );
};

export default AssumedHistoryBlock;