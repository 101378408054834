import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { CalculatedHistoryBlockProps } from '../../../helpers/Interfaces';

const HistoryContainer = styled.div<{ top: number, height: number, pixelRatio: number }>`
  position: absolute;
  top: ${({ pixelRatio, top }) => `${pixelRatio * (top)}px`};
  height: ${({ pixelRatio, height }) => `${pixelRatio * height}px`};
  left: 0;
  transform: translate(-350px, -100%);
  width: 400px;
  display: grid;
  grid-template-columns: 1fr 50px;
  background: ${({ theme }) => theme.white50};
  box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.75);

  > div {
    display: flex;
    flex-direction: ${({ height, pixelRatio }) => height * pixelRatio > 32 ? 'column' : 'row'}; 
    justify-content: ${({ height, pixelRatio }) => height * pixelRatio > 32 ? 'center' : 'space-around'};
    align-items: center;
    height: 100%;
    margin-top: ${({ height, pixelRatio }) => height * pixelRatio < 16 ? `-${(16 - height * pixelRatio) / 2}px` : '0px'};
  }
`;

const CalculatedHistoryBlock: React.FC<CalculatedHistoryBlockProps> = ({ top, height, pixelRatio, dataObject }) => {
  const { firstStation, lastStation, firstStationTimestamp, lastStationTimestamp, price } = dataObject;
  const formatTimestamp = (date: string, format: string) => moment(date).format(format)

  return (
    <HistoryContainer
      top={top}
      height={height}
      pixelRatio={pixelRatio}
    >
      <div>
        <p>{firstStation} - {lastStation}</p>
        <p>{formatTimestamp(firstStationTimestamp, "HH:mm")} - {formatTimestamp(lastStationTimestamp, "HH:mm")}</p>
      </div>
      <div>
        {price ? (price / 100).toFixed(2) : 0}€
      </div>
    </HistoryContainer>
  );
};

export default CalculatedHistoryBlock;
