import React from 'react';
import styled from 'styled-components';

import { AssumedHistory } from '../helpers/Interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../ducks/modules/rootReducer';

const SummaryContainer = styled.div<{ atHistory?: boolean }>`
  width: ${({ atHistory }) => atHistory ? '90%' : '100%'};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

const StyledGrid = styled.div<{ atHistory?: boolean }>`
  width: ${({ atHistory }) => atHistory ? '100%' : '80%'};
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  > p {
    text-align: right;
  }
`;

const HistorySummary: React.FC<{ dataToSummary: AssumedHistory[], atHistory?: boolean }> = ({ dataToSummary, atHistory }) => {
  const { version } = useSelector((state: RootState) => state.users);

  const summaryData = {
    total: dataToSummary.length,
    ok: dataToSummary.filter((historyBlock: AssumedHistory) => historyBlock.status.eventStatus === "SUCCESS").length,
    unknown: dataToSummary.filter((historyBlock: AssumedHistory) => historyBlock.status.eventStatus === "UNKNOWN").length,
    fail: dataToSummary.filter((historyBlock: AssumedHistory) => historyBlock.status.eventStatus === "FAILURE").length,
  }

  return (
    <>
      <SummaryContainer atHistory={atHistory}>
        <p>Release {version}</p>
        <StyledGrid>
          <p>OK: {summaryData.ok}</p>
          <p>FAIL: {summaryData.fail}</p>
          <p>UNKNOWN: {summaryData.unknown}</p>
          <p>TOTAL: {summaryData.total}</p>
        </StyledGrid>
      </SummaryContainer>
    </>
  );
};

export default HistorySummary;