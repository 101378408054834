import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../helpers/PrimereactComponents';

const ContentContainer = styled.div`
  position: absolute;
  height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const NotLoggedIn: React.FC = () => {
  const history = useHistory();

  const onClickLogin = useCallback(() => {
    history.push(`/`);
  }, [history]);

  return (
    <Container>
      <ContentContainer>
        <h1>You need to be logged in and have endpoint selected to see the content</h1>
        <Button onClick={onClickLogin} label="Log in" />
      </ContentContainer>
    </Container>
  );
};

export default NotLoggedIn;