import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PanelTodaysTrips } from '../../helpers/Interfaces';
import { Tooltip } from '../../helpers/PrimereactComponents';

const Container = styled.div`
  width: 100%;
  height: 30px;
  position: relative;
`;

const HoursContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HourItem = styled.div`
  text-align: center;
  border-left: 1px solid ${({ theme }) => theme.white25};
  border-right: 1px solid ${({ theme }) => theme.white25};
  flex: 1;
`;

const Box = styled.div<{ pixelRatio: number, witdth: number, left: number }>`
  position: absolute;
  top: 0px;
  left: ${({ pixelRatio, left }) => `${pixelRatio * (left + 30)}px`};
  width: ${({ pixelRatio, witdth }) => `${pixelRatio * witdth}px`};
  height: 28px;
  background: ${({ theme }) => theme.primary};
  transition: 0.2s ease-in-out;

  :hover {
    background: ${({ theme }) => theme.secondary};
  }
`;

const DailyTimeline: React.FC<{ data: PanelTodaysTrips[] }> = ({ data }) => {
  const [pixelRatio, setPixelRatio] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPixelRatio(ref.current ? ref.current.offsetWidth / (25 * 60) : 0);
  }, []);

  return (
    <>
      <HoursContainer>
        {[...Array(25)].map((_: number, i: number) => <HourItem key={i}>{i}</HourItem>)}
      </HoursContainer>
      <Container ref={ref}>
        {data.length ? data.map(({ id, endToStart, startTo00, firstStationName, lastStationName, start_time, end_time }: PanelTodaysTrips) =>
          <div key={id}>
            <Tooltip target=".box" mouseTrack mouseTrackLeft={10} />
            <Box
              className="box"
              witdth={endToStart}
              left={startTo00}
              pixelRatio={pixelRatio}
              data-pr-tooltip={`${firstStationName} - ${lastStationName}\n\n${start_time} -\n ${end_time}`} />
          </div>
        ) : ""}
      </Container>
    </>
  );
};

export default DailyTimeline;