import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { HistoryTimelineProps, AssumedHistory, CalculatedHistory } from '../../../helpers/Interfaces';
import moment from 'moment';
import CalculatedHistoryBlock from './CalculatedHistoryBlock';
import AssumedHistoryBlock from './AssumedHistoryBlock';

const Container = styled.div`
  overflow: hidden;
`;

const TimelineContainer = styled.div<{ displayTime: number, containerHeight?: number }>`
  margin: 0 auto;
  height: ${({ displayTime, containerHeight }) => containerHeight ? `${720 / displayTime * containerHeight}px` : null};
  width: 150px;
  background: ${({ theme }) => theme.black50};
  position: relative;
  transform: translateX(-100px);
`;

const Spacer = styled.div<{ index: number, containerHeight?: number, daySpacer: boolean }>`
  width: 100%;
  background: ${({ theme }) => theme.white25};
  height: 2px;
  transform: translateY(-100%);
  position: absolute;
  top: ${({ index, containerHeight }) => containerHeight ? `${containerHeight * index + 1}px` : null};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.white};

  ${({ daySpacer }) => daySpacer && css`
    height: 4px;
    font-weight: bold;
  `};

`;

const HistoryTimeline: React.FC<HistoryTimelineProps> = ({ containerHeight, assumedHistory, calculatedHistory, selectedToGenerate, setSelectedToGenerate, selectedFirstTripsDate, displayHours, hoursToDisplay, setDataToSummary }) => {
  const DISPLAY_DAYS = 30;
  const todaysDate = moment().add(1, 'd').startOf("day").format("YYYY-MM-DD HH:mm")

  const [pixelRatio, setPixelRatio] = useState<number>(0);

  const getBottomPixels = (start: string) => moment.duration(moment(todaysDate).diff(moment(start))).asMinutes();

  const getHeightPixels = (start: string, end: string) => moment.duration(moment(end).diff(moment(start))).asMinutes();

  const handleHistoryRectangleClick = (dataObj: AssumedHistory) => {
    if (selectedToGenerate.length === 0) {
      setSelectedToGenerate([...selectedToGenerate, dataObj])
    }
    if (selectedToGenerate.filter((selected: AssumedHistory) => selected?._id === dataObj._id).length) {
      setSelectedToGenerate(selectedToGenerate.filter((selected: AssumedHistory) => selected._id !== dataObj._id))
    } else {
      if (selectedFirstTripsDate && moment(selectedFirstTripsDate).isSame(moment(dataObj.firstEvent.timestamp).format("YYYY-MM-DD"))) {
        setSelectedToGenerate([...selectedToGenerate, dataObj])
      } else {
        setSelectedToGenerate([dataObj])
      }
    }
  }

  useEffect(() => {
    setPixelRatio(containerHeight ? containerHeight / (displayHours * 60) : 0);
  }, [containerHeight, displayHours])

  return (
    <Container>
      <TimelineContainer displayTime={displayHours} containerHeight={containerHeight}>
        {[...Array((DISPLAY_DAYS * 24 / displayHours + 1))].map((_: number, i: number) =>
          <Spacer index={i} containerHeight={containerHeight} key={i} daySpacer={i % (24 / displayHours) === 0}>
            <p>{`${DISPLAY_DAYS * 24 / displayHours === i ? '00' : hoursToDisplay[i % (24 / displayHours)]}:00`}</p>
            <p>{moment().subtract(Math.floor(i / (24 / displayHours)), 'd').format("DD-MM-YYYY")}</p>
          </Spacer>
        )}
        {calculatedHistory.length ? calculatedHistory.map((dataObj: CalculatedHistory, i: number) =>
          <CalculatedHistoryBlock
            top={getBottomPixels(dataObj.firstStationTimestamp)}
            height={getHeightPixels(dataObj.firstStationTimestamp, dataObj.lastStationTimestamp)}
            pixelRatio={pixelRatio}
            key={i}
            dataObject={dataObj}
          />
        ) : null}
        {assumedHistory.length ? assumedHistory.map((dataObj: AssumedHistory) =>
          <AssumedHistoryBlock
            top={getBottomPixels(dataObj.firstEvent.timestamp)}
            height={getHeightPixels(dataObj.firstEvent.timestamp, dataObj.lastEvent.timestamp)}
            pixelRatio={pixelRatio}
            key={dataObj._id}
            dataObject={dataObj}
            handleClick={() => handleHistoryRectangleClick(dataObj)}
            setDataToSummary={setDataToSummary}
            selected={Boolean(selectedToGenerate.filter((selected: AssumedHistory) => selected?._id === dataObj._id).length)}
          />
        ) : null}
      </TimelineContainer>
    </Container >
  );
};

export default HistoryTimeline;
