import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import { SidebarItemProps } from '../../helpers/Interfaces';

const Container = styled.div<{ subChild?: boolean, isSidebarVisible?: boolean, tooltip: string }>`
  width: 100%;
  color: ${({ theme }) => theme.white25};
  font-size: 16px;
  margin: 0px auto;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  :hover {
    font-size: 18px;
  }

  ${({ isSidebarVisible }) => isSidebarVisible && css`
    padding: 10px 0 10px 10px;
  `};

  ${({ subChild }) => subChild && css`
    font-size: 14px;

    :hover {
      font-size: 14px;
    }
  `};
`;

const Icon = styled.i<{ isSidebarVisible?: boolean }>`
  margin:0 auto;
  padding: 0 5px;

  ${({ isSidebarVisible }) => isSidebarVisible && css`
    margin: 0 10px 0 0;
  `};
`;

const SidebarItem: React.FC<SidebarItemProps> = ({ active, text, onClick, children, subChild, isSidebarVisible }) => {

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <Container isSidebarVisible={isSidebarVisible} subChild={subChild} style={active ? { 'background': '#555', 'fontWeight': 'bold' } : {}} onClick={handleOnClick} tooltip={text}>
      <Icon isSidebarVisible={isSidebarVisible} className="icon" data-pr-tooltip={typeof text === 'string' ? text : text.props.children[0]} >{children}</Icon> {isSidebarVisible ? text : null}
    </Container>
  );
};

export default SidebarItem;
