import React from 'react';
import { Header } from '../styles/PanelStyles';
import { DataTable, Column } from '../helpers/PrimereactComponents';
import { CasesTableProps } from '../helpers/Interfaces';

const CasesTable: React.FC<CasesTableProps> = ({ journeysToDisplay, selectedExisting, setSelectedExisting, selectedSavedJourneyCases, selectedCase, setSelectedCase }) => {
  const columnHeaderStyles = { 'background': '#2196f3', 'color': '#ffffff' };

  return (
    <>
      <div>
        <Header smallest>Saved trips</Header>
        <DataTable
          value={journeysToDisplay}
          scrollable
          scrollHeight="200px"
          selection={selectedExisting}
          selectionMode="single"
          dataKey={"_id"}
          onSelectionChange={(e) => setSelectedExisting(e.value)}
        >
          <Column field="firstStation" header="First station" headerStyle={columnHeaderStyles} />
          <Column field="lastStation" header="Last station" headerStyle={columnHeaderStyles} />
          <Column field="stationsCount" header="Stations" headerStyle={columnHeaderStyles} />
          <Column field="connectionsCount" header="Connections" headerStyle={columnHeaderStyles} />
        </DataTable>
      </div>
      {selectedExisting &&
        <div>
          <Header smallest>Saved cases</Header>
          <DataTable
            value={selectedSavedJourneyCases}
            selection={selectedCase}
            onSelectionChange={(e) => setSelectedCase(e.value)}
            selectionMode="single"
            dataKey={"_id"}
            scrollable
            scrollHeight="200px"
          >
            <Column field="caseName" header="Case name" headerStyle={columnHeaderStyles} />
          </DataTable>
        </div>}
    </>
  );
};

export default CasesTable;