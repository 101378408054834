import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../ducks/modules/rootReducer";
import { setLoggedUserDataAction, setSelectedEndpointAction } from '../ducks/modules/usersReducer'
import { Link } from "react-router-dom";
import UserService from '../services/UserService';
import { endpoints, users, TEMP_ENDPOINT, PROD_ENDPOINT } from '../helpers/loginData';

import Modal from '../components/Modal';
import { Dropdown, InputText } from '../helpers/PrimereactComponents';
import { Container, LoginContainer, StyledButton, ModalContent } from '../styles/LoginStyles';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((state: RootState) => state.users);

  const [selectedUser, setSelectedUser] = useState({ name: localStorage.getItem("test-tool-username"), token: localStorage.getItem("test-tool-token") });
  const [selectedEndpoint, setSelectedEndpoint] = useState(localStorage.getItem("test-tool-endpoint"));
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onClickLogout = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(setLoggedUserDataAction());
      UserService.doLogout();
    },
    [dispatch],
  );

  const handleUserChange = useCallback(
    ({ value }: any) => {
      setSelectedUser(value);
      dispatch(setLoggedUserDataAction(value.name));
      localStorage.setItem("test-tool-username", value.name)
      localStorage.setItem("test-tool-token", value.token)
    },
    [dispatch]
  );

  const handleEndpointChange = useCallback(({ value }: any) => {
    if (value !== PROD_ENDPOINT) {
      setSelectedEndpoint(value);
      dispatch(setSelectedEndpointAction(value));
      localStorage.setItem("test-tool-endpoint", value);
    } else {
      setIsModalOpen(true);
    }
  }, [dispatch]);

  const chooseModalsOption = (endpoint: string) => {
    setSelectedEndpoint(endpoint);
    dispatch(setSelectedEndpointAction(endpoint));
    localStorage.setItem("test-tool-endpoint", endpoint);
    setIsModalOpen(false);
  }

  return (
    <Container>
      <LoginContainer>
        <h1> Welcome <span>{loggedUser}</span></h1>
        <h2>Select user:</h2>
        <Dropdown
          options={users}
          optionLabel="name"
          value={selectedUser}
          onChange={handleUserChange}
        />
        <h2>Select endpoint:</h2>
        <Dropdown
          options={endpoints}
          optionLabel="name"
          optionValue="endpoint"
          value={selectedEndpoint}
          onChange={handleEndpointChange}
        />
        <Link to={"/panel"}>
          <StyledButton label="Start testing" />
        </Link>
        <StyledButton label="Logout from keycloak" onClick={onClickLogout} />
        <span>Token:</span>
        <InputText value={UserService.getToken()} disabled />
      </LoginContainer>
      {isModalOpen &&
        <Modal header="Do you want to choose production?" closeModalFunction={() => setIsModalOpen(false)} >
          <ModalContent>
            <StyledButton label="Yes" onClick={() => chooseModalsOption(PROD_ENDPOINT)} />
            <StyledButton label="No" onClick={() => chooseModalsOption(TEMP_ENDPOINT)} className="p-button-danger" />
          </ModalContent>
        </Modal>}
    </Container >
  );
};

export default Login;
