import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from '../theme';
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header";
import NotLoggedIn from "../components/NotLoggedIn";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../ducks/modules/rootReducer';
import { ToastContainer } from 'react-toastify';
import Loading from '../components/Loading';

const Column = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  transition: 0.4s ease-in-out;
  overflow-Y: scroll;
  background: #ddd;
`;

const LayoutTemplate: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { loggedUser, selectedEndpoint, loading, multiGeneratingLoading, loaderProgress } = useSelector((state: RootState) => state.users);
  const { journeyBaseId } = useSelector((state: RootState) => state.columns);

  const searchParams = new URLSearchParams(useLocation().search);
  const caseId = searchParams.get("caseId");
  const path = useLocation().pathname

  return (
    <ThemeProvider theme={theme}>
      <Column>
        <Header />
        <Row>
          <Sidebar journeyBaseId={journeyBaseId} caseId={caseId} />
          {loggedUser && selectedEndpoint || path === '/' ? <ContentContainer>{children}</ContentContainer> : <NotLoggedIn />}
        </Row>
        <ToastContainer
          position="bottom-center"
        />
        {multiGeneratingLoading && <Loading content={loaderProgress} />}
        {loading && <Loading />}
      </Column>
    </ThemeProvider>
  );
};

export default LayoutTemplate;
