import React, { useState, useEffect, useCallback, memo } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCheckedAction,
  changeSelectedValueAction,
  dropdownsChangedByCheckboxAction,
  updateColumnValuesAction
} from "../../ducks/modules/columnsReducer";
import ColumnField from './ColumnField';
import Checkbox from '../Checkbox';
import { RootState } from '../../ducks/modules/rootReducer';
import eventsList from '../../helpers/eventsList';
import { SingleColumnProps, FieldInfo } from '../../helpers/Interfaces';

const StyledContainer = styled.div<{ fixedColumn?: boolean }>`
  position: relative;
  width: 160px;
  
  ${({ fixedColumn }) => fixedColumn && css`
    background: ${({ theme }) => theme.white};
    position: fixed;
    top: 0;
    margin-top: 15vh;
    padding-top: 100px;
    background: #${({ theme }) => theme.white};
    z-index: 10;
  `}
`;

const fixedValues = ['GPS', 'SPEED', 'MOTION DETECTION', 'EVENTS', 'GEO_IN', 'GEO_OUT', 'SWIPE_IN', 'SWIPE_OUT'];

const SingleColumn: React.FC<SingleColumnProps> = ({ fixedColumn, values = fixedValues, id }) => {
  const dispatch = useDispatch();
  const { checkedBoxes, fixedCheckbox, shouldGenerateRandom, data } = useSelector((state: RootState) => state.columns);

  const [checked, setChecked] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);
  const [unlockButtonsKey, setUnlockButtonsKey] = useState<null | string>(null);
  const [toPass, setToPass] = useState(values);
  const [forcedValues, setForcedValues] = useState<any>([]);

  const randomizedValues = shouldGenerateRandom ? values.map((value: any, index: number) => {
    if (index < 3) {
      if (value.default) {
        return value?.options[Math.floor(Math.random() * value?.options?.length)].value;
      } else return value;
    } else {
      return value;
    }
  }) : values;

  useEffect(() => {
    let result: any[] = [];
    if (randomizedValues) {
      result = values.map((val: any, i: number) => {
        if (val.name) {
          if (i < 3) {
            return val?.options[Math.floor(Math.random() * val?.options?.length)].value;
          } else return data.length ? data[id as number][val.name] : false;
        } else {
          return val;
        }
      })
    }
    setForcedValues(result)
    const fieldNames = ["GPS", "SPEED", "MOTION_DETECTION", "", "GEO_IN", "GEO_OUT", "SWIPE_IN", "SWIPE_OUT"];
    const newColumn = result.map((value: string, index: number) => ({ [fieldNames[index]]: value })).filter((val: any, idx: number) => idx !== 3);
    let neww: any = {}
    newColumn.forEach((keyVal: any, index: number) => {
      for (var key in keyVal) {
        neww = { ...neww, [key]: keyVal[key] }
      }
    });
    dispatch(updateColumnValuesAction(id as number, neww))
    // eslint-disable-next-line
  }, [shouldGenerateRandom]);

  useEffect(() => { // changing columns which checkboxes were selected
    if (id as number >= 0) {
      setChecked(checkedBoxes[id as number]);
    } else {
      setChecked(fixedCheckbox);
    }

    if (typeof wasChanged === 'object') {
      const { columnId, name, value } = wasChanged;
      if (!eventsList.includes(name)) {
        let trueElements: number[] = [];
        for (let i = 0; i < checkedBoxes.length - 1; i++) {
          if (checkedBoxes[i] === true) trueElements.push(i);
        };

        let elementsToChange: FieldInfo[] = [];
        if (trueElements.includes(columnId)) {
          trueElements.forEach((num: number) => elementsToChange.push({ columnId: num, name, value }));
          trueElements.forEach((num: number) => dispatch(changeSelectedValueAction(num, name, value)));
        }

        dispatch(dropdownsChangedByCheckboxAction(elementsToChange));
      }
      setWasChanged(false)
    }
  }, [wasChanged, dispatch, fixedCheckbox, id, randomizedValues, checkedBoxes]);

  useEffect(() => { // unlocking all events when randomizing values
    if (shouldGenerateRandom) {
      setUnlockButtonsKey(null);
    }
  }, [shouldGenerateRandom])

  useEffect(() => {
    if (data) { // setting unlock key on event that is turned on
      for (let i = 0; i < eventsList.length; i++) {
        if (data[id as number] && data[id as number][eventsList[i]] === true) {
          setUnlockButtonsKey(eventsList[i])
          break;
        } else {
          setUnlockButtonsKey(null);
        }
      }
    }
    let localForced: any[] = [];
    setToPass(values.map((value: any) => { // converting 'ON'/'OFF' recieving from backend to true/false
      const { name } = value;
      if (data[id as number]) {
        localForced.push(data[id as number][name]);
        // if (name) return { ...value, default: data[id as number][name] }
        if (name) return { ...value }
        else return '';
      } else if (id as number === undefined) {
        return value;
      } else {
        return 'loading..';
      }
    }));
    setForcedValues(localForced)
  }, [data, id, values])

  const handleSetChecked = useCallback(() => {
    dispatch(changeCheckedAction(id as number >= 0 ? id : 'fixed'));
    setChecked(!checked);
  }, [checked, dispatch, id]);

  return (
    <StyledContainer fixedColumn={fixedColumn} >
      <ColumnField checkbox columnId={id} fieldValue={<Checkbox checked={checked} setChecked={handleSetChecked} columnId={id as number >= 0 ? id : 'fixed'} />} />
      {toPass && toPass.map((value: any, index: number) =>
        <ColumnField
          key={index}
          columnId={id}
          fieldValue={value}
          setWasChanged={setWasChanged}
          unlockButtonsKey={unlockButtonsKey}
          setUnlockButtonsKey={setUnlockButtonsKey}
          forcedValue={forcedValues[index]}
        />)}
    </StyledContainer>
  );
};

function areEqual(prevProps: any, nextProps: any) {
  return prevProps.values === nextProps.values && prevProps.id === nextProps.id;
}

export default memo(SingleColumn, areEqual);
