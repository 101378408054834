import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { TimelineProps, Stop } from '../../helpers/Interfaces';

const Container = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 20px;
  padding: 20px;
`;

const Line = styled.div`
  width: 100%;
  height: 4px;
  background: ${({ theme }) => theme.secondary};
`;

const DotsContainer = styled.div`
  position: relative;
  margin-top: -12px;
`;

const TimelinePoint = styled.div<{ atEdges?: boolean, left?: any, pixelRatio?: any }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  :nth-child(even) {
    flex-direction: column-reverse;
    top: -36px;
  }

  left: ${({ pixelRatio, left }) => `${25 + pixelRatio * Math.round(left)}px`};

  ${({ atEdges }) => atEdges && css`
    :first-child {
      left: 25px;
    }
    :last-child {
      right: -25px;
    }
  `};
`;

const TimelineDot = styled.div`
  background: ${({ theme }) => theme.primary};
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const TripfinderTimeline: React.FC<{ data: TimelineProps }> = ({ data }) => {
  const [timelineData, setTimelineData] = useState<{ first: string; output: Stop[] }>({ first: "", output: [] });
  const [pixelRatio, setPixelRatio] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let output: any[] = [];
    output.push(data.legs[0].firstStop);
    if (data.transfers) {
      for (let i = 0; i < data.transfers; i++) {
        const thisLastStop = data.legs[i].stops[data.legs[i].stops.length - 1];
        const nextFirstStop = data.legs[i + 1].stops[0];
        output.push({ ...thisLastStop, departureDateTime: nextFirstStop.departureDateTime });
      }
    }
    output.push(data.legs[data.legs.length - 1].lastStop);
    const first = output[0].departureDateTime;
    const last = output[output.length - 1].arrivalDateTime;
    const tripDuration = moment.duration(moment(last).diff(moment(first))).asMinutes();
    setTimelineData({ output, first });
    setPixelRatio(ref.current ? (ref.current.offsetWidth - 25) / tripDuration : 0);
  }, [data])

  const createTimestamp = (arr?: string, dep?: string) => {
    if (arr && dep) return `${moment(arr).format("HH:mm")} - ${moment(dep).format("HH:mm")}`;
    else if (arr) return moment(arr).format("HH:mm");
    else return moment(dep).format("HH:mm");
  }

  return (
    <Container>
      <Line ref={ref} />
      <DotsContainer>
        {timelineData.output.length ? timelineData.output.map(({ code, name, arrivalDateTime, departureDateTime }: any, i: number) => {
          if (i === 0 || i === timelineData.output.length - 1) {
            return (
              <TimelinePoint key={code} atEdges>
                <TimelineDot />
                <p>{name}</p>
                <p>{createTimestamp(arrivalDateTime, departureDateTime)}</p>
              </TimelinePoint>
            )
          } else {
            return (
              <TimelinePoint key={code} left={moment.duration(moment(arrivalDateTime).diff(moment(timelineData.first))).asMinutes()} pixelRatio={pixelRatio}>
                <TimelineDot />
                <p>{name}</p>
                <p>{createTimestamp(arrivalDateTime, departureDateTime)}</p>
              </TimelinePoint>
            )
          }
        }) : null}
      </DotsContainer>
    </Container>
  );
};

export default TripfinderTimeline;
