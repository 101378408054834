import React, { useState, useEffect } from 'react';
import { APIService } from '../services/ApiService';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setVersionAction } from '../ducks/modules/usersReducer';

import HistorySummary from '../components/HistorySummary';
import { AssumedHistory, VersionData, VersionId, VersionInfo } from '../helpers/Interfaces';
import { DataTable, Column, InputText, Button } from '../helpers/PrimereactComponents';
import { Container, VersionsContainer, StyledTextarea, StyledFlex, BackButton, StyledDropdown } from '../styles/VersionsStyles';

const Versions: React.FC = () => {
  const dispatch = useDispatch();

  const [versions, setVersions] = useState<{ allVersions: VersionInfo[], usedVersions: VersionData[] }>({ allVersions: [], usedVersions: [] });
  const [newVersionCode, setNewVersionCode] = useState<string>("");
  const [newVersionNote, setNewVersionNote] = useState<string>("");
  const [selectedVersion, setSelectedVersion] = useState<VersionData | null>(null);
  const [filterValue, setFilterValue] = useState("ALL");

  const filterData = ["ALL", "SUCCESS", "UNKNOWN", "FAILURE"];

  useEffect(() => {
    getVersions();
  }, [])

  const getVersions = async () => {
    const usedVersions = await APIService.get('/history/statuses/versions').then(({ data }) => data);
    let allVersions = await APIService.get('/versions').then(({ data }) => data);

    allVersions = allVersions.map((version: VersionInfo) => ({ ...version, generated: usedVersions.filter((usedVersion: VersionData) => usedVersion._id._id === version._id)[0]?.events?.length || 0 }))
    setVersions({ allVersions, usedVersions })
  }

  const createdTemplate = ({ createdAt }: VersionId) => moment(createdAt).format("DD-MM-YYYY HH:mm");

  const dateTemplate = ({ firstEvent }: AssumedHistory) => moment(firstEvent.timestamp).format("DD-MM-YYYY")
  const usecaseDescriptionTemplate = ({ firstEvent, lastEvent }: AssumedHistory) => (
    <div>
      <p>{firstEvent.stationName} - {lastEvent.stationName}</p>
      <p>{moment(firstEvent.timestamp).format("HH:mm")} - {moment(lastEvent.timestamp).format("HH:mm")}</p>
    </div>);
  const testResultTemplate = ({ status }: AssumedHistory) => status.eventStatus
  const commentTemplate = ({ status }: AssumedHistory) => status.eventStatus === "SUCCESS" ? null : status.failureNote

  const addNewVersion = () => {
    if (newVersionCode) {
      APIService.post('/versions', {
        versionCode: newVersionCode,
        versionNote: newVersionNote,
      }).then(() => {
        getVersions();
        dispatch(setVersionAction(newVersionCode));
        setNewVersionCode("");
        setNewVersionNote("");
      });
    }
  }

  return (
    <Container>
      <VersionsContainer>
        {selectedVersion ? <>
          <StyledFlex>
            <StyledDropdown options={filterData} onChange={(e: any) => setFilterValue(e.target.value)} value={filterValue} />
            <HistorySummary dataToSummary={selectedVersion.events} />
          </StyledFlex>
        </> : <h1>Versions</h1>}

        {selectedVersion && <BackButton label="Back" onClick={() => setSelectedVersion(null)} />}
        {selectedVersion ? <DataTable
          value={filterValue === "ALL" ? selectedVersion?.events : selectedVersion?.events.filter((event: AssumedHistory) => event.status.eventStatus === filterValue)}
          scrollable
          scrollHeight="100%"
        >
          <Column field="firstEvent.timestamp" header="Date" body={dateTemplate} sortable />
          <Column header="Usecase description" body={usecaseDescriptionTemplate} />
          <Column header="Test result" body={testResultTemplate} />
          <Column header="Comment" body={commentTemplate} />
        </DataTable> : <DataTable
          value={versions.allVersions}
          scrollable
          scrollHeight="120%"
          selection={selectedVersion}
          onSelectionChange={(e) => setSelectedVersion(versions?.usedVersions.filter(({ _id }: VersionData) => _id._id === e.value._id)[0])}
          selectionMode="single"
          dataKey={"_id"}
          sortField="versionCode"
          sortOrder={-1}
        >
            <Column field="versionCode" header="Version" />
            <Column field="createdAt" header="Created" body={createdTemplate} />
            <Column field="versionNote" header="Note" />
            <Column field="generated" header="Generated" />
          </DataTable>}
        <StyledFlex forButtons>
          <InputText value={newVersionCode} onChange={(e: any) => setNewVersionCode(e.target.value)} placeholder="version" />
          <StyledTextarea value={newVersionNote} onChange={(e: any) => setNewVersionNote(e.target.value)} placeholder="description(optional)" />
          <Button onClick={addNewVersion} disabled={!newVersionCode} label="Add" />
        </StyledFlex>
      </VersionsContainer>
    </Container>
  );
};

export default Versions;
