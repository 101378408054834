import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import SingleColumn from './SingleColumn';
import { StationGroupProps } from '../../helpers/Interfaces';
import moment from 'moment';

const StyledContainer = styled.div`
  display: flex;
`;

const StyledPartContainer = styled.div<{ atStation?: boolean }>`
  display: flex;
  background: ${({ theme }) => theme.white};

  ${({ atStation }) => atStation && css`
    background: ${({ theme }) => theme.white50};
  `}
  display: grid;
`;

const StyledColumnsContainer = styled.div`
  display: flex;
`;

const StyledHeader = styled.div<{ atStation?: boolean }>`
  height: 100px;
  color: ${({ theme }) => theme.primary};
  font-weight: bold;
  text-align: center;
  padding: 10px;

  ${({ atStation }) => atStation && css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledTimestampContainer = styled.div`
  width: 100px;
  align-self: flex-end;
  font-size: 12px;
`;

const columnFields = [
  { name: 'GPS', options: [{ label: 'Good', value: 'GOOD' }, { label: 'Moderate', value: 'MODERATE' }, { label: 'Bad', value: 'BAD' }], default: '' },
  { name: 'SPEED', options: [{ label: 'Fast', value: 'FAST' }, { label: 'Medium', value: 'MEDIUM' }, { label: 'Slow', value: 'SLOW' }, { label: 'Zero', value: 'ZERO' }], default: '' },
  { name: 'MOTION_DETECTION', options: [{ label: 'In vehicle', value: 'IN_VEHICLE' }, { label: 'Walking', value: 'WALKING' }, { label: 'Unknown', value: 'UNKNOWN' }], default: '' },
  '',
  { name: 'GEO_IN', options: [{ label: 'ON', value: true }, { label: 'OFF', value: false }], default: false },
  { name: 'GEO_OUT', options: [{ label: 'ON', value: true }, { label: 'OFF', value: false }], default: false },
  { name: 'SWIPE_IN', options: [{ label: 'ON', value: true }, { label: 'OFF', value: false }], default: false },
  { name: 'SWIPE_OUT', options: [{ label: 'ON', value: true }, { label: 'OFF', value: false }], default: false },
];

const StationGroup: React.FC<StationGroupProps> = ({ stationData, index, total }) => {

  const getTimestampString = (timestamp: string | undefined) => timestamp ? <>
    <p>{moment(timestamp).format('DD-MM-YYYY')}</p>
    <p>{moment(timestamp).format('HH:mm:ss')}</p>
  </> : null;

  const { code, name, departureDateTime, arrivalDateTime, trainId } = stationData;

  return (
    <StyledContainer>
      <StyledPartContainer atStation>
        <StyledHeader atStation>
          <StyledTimestampContainer>
            {arrivalDateTime && (
              <>
                <p>train: {trainId}</p>
                <p>arrival date:</p>
                {getTimestampString(arrivalDateTime)}
              </>
            )}
          </StyledTimestampContainer>
          <div>
            <h1>{name}</h1>
            <h1>{code}</h1>
          </div>
          <StyledTimestampContainer>
            {departureDateTime && (
              <>
                <p>train: {trainId}</p>
                <p>departure date:</p>
                {getTimestampString(departureDateTime)}
              </>
            )}
          </StyledTimestampContainer>
        </StyledHeader>
        <StyledColumnsContainer>
          <SingleColumn id={0 + 9 * index} values={columnFields} />
          <SingleColumn id={1 + 9 * index} values={columnFields} />
          <SingleColumn id={2 + 9 * index} values={columnFields} />
          <SingleColumn id={3 + 9 * index} values={columnFields} />
        </StyledColumnsContainer>
      </StyledPartContainer>
      <StyledPartContainer>
        <StyledHeader></StyledHeader>

        <StyledColumnsContainer>
          <SingleColumn id={4 + 9 * index} values={columnFields} />
          <SingleColumn id={5 + 9 * index} values={columnFields} />
          {index < total - 1 && (
            <>
              <SingleColumn id={6 + 9 * index} values={columnFields} />
              <SingleColumn id={7 + 9 * index} values={columnFields} />
              <SingleColumn id={8 + 9 * index} values={columnFields} />
            </>
          )}
        </StyledColumnsContainer>
      </StyledPartContainer>
    </StyledContainer>
  )
};

function areEqual(prevProps: StationGroupProps, nextProps: StationGroupProps) {
  return prevProps.stationData === nextProps.stationData && prevProps.total === nextProps.total;
}

export default memo(StationGroup, areEqual);