import { keyframes } from 'styled-components';

export const appearWithTranslate = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  } 70% {
    opacity: 0.8;
    transform: translate(-50%, -50%) scale(1.09);
  } 100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const appear = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  } 70% {
    opacity: 0.8;
    transform: scale(1.09);
  } 100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const scaleLoader = keyframes`
  from {
    transform: translate(-50%, -50%) scale(1);
  } to {
    transform: translate(-50%, -50%) scale(3);
  }
`;