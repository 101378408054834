import React, { useCallback, useState, useEffect } from 'react';
import {
  StyledContainer,
  StyledUserInfoContainer,
  StyledUserAvatar,
  StyledUserInfo,
  StyledUserInfoParagraph,
  StyledToggleButton,
  SavedCasesContainer,
  ExpandArrow
} from '../../styles/SidebarStyles';
import SidebarItem from './SidebarItem';
import { APIService } from '../../services/ApiService';
import { useHistory, useLocation } from "react-router-dom";
import { setUsersCasesAction, setVersionAction } from '../../ducks/modules/usersReducer';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../ducks/modules/rootReducer';
import { SidebarProps } from '../../helpers/Interfaces';
import { Tooltip } from '../../helpers/PrimereactComponents';

const Sidebar: React.FC<SidebarProps> = ({ journeyBaseId, caseId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loggedUser, usersCases, version } = useSelector((state: RootState) => state.users);

  const searchParams = new URLSearchParams(useLocation().search);
  const firstStationCode = searchParams.get("firstStationCode");
  const lastStationCode = searchParams.get("lastStationCode");
  const firstStationName = searchParams.get("firstStationName");
  const lastStationName = searchParams.get("lastStationName");
  const timestamp = searchParams.get("timestamp");
  const case_editor = searchParams.get("case_editor");

  const [currentPath, setCurrentPath] = useState<string | null>(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(true);
  const [areCasesHidden, setAreCasesHidden] = useState(false);

  useEffect(() => {
    if (journeyBaseId) {
      dispatch(setUsersCasesAction(journeyBaseId))
    }
  }, [journeyBaseId, loggedUser, dispatch]);

  useEffect(() => {
    APIService.get('/versions').then((res) => dispatch(setVersionAction(res.data.reverse()[0]?.versionCode)));
  }, [dispatch]);

  const onClickPanel = useCallback(() => {
    history.push('/panel')
  }, [history]);
  const onClickHistory = useCallback(() => {
    history.push('/history')
  }, [history]);
  const onClickVersions = useCallback(() => {
    history.push('/versions')
  }, [history]);
  const onClickCase = useCallback((caseId: string) => {
    !case_editor ? history.push(`/trip?firstStationName=${firstStationName}&lastStationName=${lastStationName}&firstStationCode=${firstStationCode}&lastStationCode=${lastStationCode}&timestamp=${timestamp}&caseId=${caseId}`) :
      history.push(`/trip?firstStationName=${firstStationName}&lastStationName=${lastStationName}&firstStationCode=${firstStationCode}&lastStationCode=${lastStationCode}&timestamp=${timestamp}&caseId=${caseId}&case_editor=1`);
  }, [history, firstStationName, lastStationName, firstStationCode, lastStationCode, timestamp, case_editor]);
  useEffect(() => {
    setCurrentPath(history.location.pathname);
  }, [history.location]);

  return (
    <StyledContainer isSidebarVisible={isSidebarVisible}>
      {!isSidebarVisible && <Tooltip target=".icon" mouseTrack />}
      {loggedUser && <div>
        <StyledUserInfoContainer isSidebarVisible={isSidebarVisible}>
          <StyledUserAvatar />
          <StyledUserInfo>
            <StyledUserInfoParagraph>Welcome</StyledUserInfoParagraph>
            <StyledUserInfoParagraph isName>{loggedUser}</StyledUserInfoParagraph>
          </StyledUserInfo>
        </StyledUserInfoContainer>
        <SidebarItem onClick={onClickPanel} isSidebarVisible={isSidebarVisible} active={currentPath === '/panel'} text="Panel"><i className="pi pi-list" /></SidebarItem>
        <SidebarItem onClick={onClickHistory} isSidebarVisible={isSidebarVisible} active={currentPath === '/history'} text="History"><i className="pi pi-calendar-minus" /></SidebarItem>
        <SidebarItem onClick={onClickVersions} isSidebarVisible={isSidebarVisible} active={currentPath === '/versions'} text={`Version ${version}`}><i className="pi">v</i></SidebarItem>
        <hr />
        {firstStationCode &&
          <>
            {!isSidebarVisible && <Tooltip target=".icon" mouseTrack />}
            <SidebarItem isSidebarVisible={isSidebarVisible} text={<>Saved Cases <ExpandArrow className="pi pi-angle-down" hidden={areCasesHidden} /></>} onClick={() => setAreCasesHidden(!areCasesHidden)}><i className="pi pi-save" /></SidebarItem>
            <SavedCasesContainer isHidden={areCasesHidden} arrayLen={usersCases.length}>
              <SidebarItem
                onClick={() => onClickCase('sunny_case')}
                subChild
                text="Sunny case"
                active={caseId === 'sunny_case'}
                isSidebarVisible={isSidebarVisible}
              ><i className="pi pi-star-o" /></SidebarItem>
              {usersCases.length ? usersCases.map(({ _id, caseName }: { _id: string, caseName: string }) =>
                <SidebarItem
                  onClick={() => onClickCase(_id)}
                  key={_id}
                  subChild
                  text={caseName}
                  active={caseId === _id}
                  isSidebarVisible={isSidebarVisible}
                ><i className="pi pi-star-o" /></SidebarItem>
              ) : '0 cases saved'}
            </SavedCasesContainer>
          </>}
      </div>}
      <StyledToggleButton isSidebarVisible={isSidebarVisible} onClick={() => setIsSidebarVisible(!isSidebarVisible)}><i className="pi pi-angle-left" /></StyledToggleButton>
    </StyledContainer >
  );
};

export default Sidebar;
