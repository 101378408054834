import { Stop, routeDetailsInterface } from '../helpers/Interfaces';

const generateSunnyCase = (columns: number, routeDetails: routeDetailsInterface, allStations: Stop[]) => {
  let output = [];
  const speedValues = ['SLOW', 'ZERO', 'ZERO', 'SLOW', 'MEDIUM', 'MEDIUM', 'FAST', 'MEDIUM', 'MEDIUM'];
  let columnsToChange = [];
  if (Object.keys(routeDetails).length !== 0) {
    const transfers = routeDetails.trips[0].transfers;
    let stationsToChangeIndexes = [];
    for (let i = 0; i < transfers; i++) {
      stationsToChangeIndexes.push(allStations.findIndex((station: Stop) => station.code === routeDetails.trips[0].legs[i].stops[routeDetails.trips[0].legs[i].stops.length - 1].code));
    }
    if (stationsToChangeIndexes[0] === -1) stationsToChangeIndexes = [];
    for (let i = 0; i < stationsToChangeIndexes.length; i++) {
      for (let j = 0; j <= 10; j++) {
        columnsToChange.push(stationsToChangeIndexes[i] * 9 + 1 + j);
      }
    }
  }
  for (let i = 0; i < columns; i++) {
    if (columnsToChange.includes(i)) {
      output.push({
        GPS: 'GOOD',
        SPEED: 'SLOW',
        MOTION_DETECTION: 'WALKING',
        GEO_IN: false,
        GEO_OUT: false,
        SWIPE_IN: false,
        SWIPE_OUT: false,
      })
    } else {
      output.push({
        GPS: 'GOOD',
        SPEED: i < columns - 2 ? speedValues[i % 9] : 'SLOW',
        MOTION_DETECTION: [0, 1, 2, columns - 5, columns - 4, columns - 3, columns - 2, columns - 1].includes(i) ? 'WALKING' : 'IN_VEHICLE',
        GEO_IN: i > 0 && i % 9 === 0,
        GEO_OUT: i % 9 === 4 || i === columns - 2,
        SWIPE_IN: i === 0,
        SWIPE_OUT: i === columns - 1,
      });
    }
  };
  return output;
};

export default generateSunnyCase;