import React, { useCallback } from 'react';
import styled from 'styled-components';
import Dropdown from '../Dropdown';
import { useDispatch } from 'react-redux';
import { changeSelectedValueAction } from '../../ducks/modules/columnsReducer';
import eventsList from '../../helpers/eventsList';
import EventField from '../EventField';
import { ColumnFieldProps } from '../../helpers/Interfaces';

const StyledContainer = styled.div`
  position: relative;
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.white50};
  transition: 0.2s ease-in-out;
  text-align: center;
`;

const ColumnField: React.FC<ColumnFieldProps> = ({ fieldValue, columnId, checkbox, setWasChanged, unlockButtonsKey, setUnlockButtonsKey, forcedValue }) => {
  const dispatch = useDispatch();

  const handleChange = useCallback(({ columnId, name, value }: { columnId: number, name: string, value: string | boolean }) => {
    setWasChanged({ columnId, name, value });
    dispatch(changeSelectedValueAction(columnId, name, value));
  }, [dispatch, setWasChanged]);

  return (
    <StyledContainer>
      {checkbox || typeof fieldValue === 'string' ? fieldValue : eventsList.includes(fieldValue.name) ?
        (<EventField
          columnId={columnId}
          buttonsData={fieldValue}
          handleButtonChange={handleChange}
          unlockButtonsKey={unlockButtonsKey}
          setUnlockButtonsKey={setUnlockButtonsKey}
          forcedValue={forcedValue}
        />
        ) : (
          <Dropdown
            columnId={columnId}
            dropdownData={fieldValue}
            handleSelectChange={handleChange}
            forcedValue={forcedValue}
          />)
      }
    </StyledContainer>
  );
};

export default ColumnField;
