import styled from "styled-components";
import { appear } from '../helpers/keyframes';
import { Button } from '../helpers/PrimereactComponents';

export const Container = styled.div`
  margin: 120px 60px;
`;

export const LoginContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  padding: 20px;
  background: ${({ theme }) => theme.white};
  animation: ${appear} 0.3s ease-in-out;

  h1 {
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export const StyledButton = styled(Button)`
  margin: 20px 0 10px 0;
  min-width: 160px;
`;

export const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;  
`;