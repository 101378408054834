import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  min-width: 80vw;
  max-width: 85vw;
  margin: 0 auto;
  height: 80vh;
  background: ${({ theme }) => theme.white};
  overflow-Y: scroll;
  scroll-behavior: smooth;
`;

export const Header = styled.div`
  margin: 0 auto;
  height: 5vh;
  display: grid;
  grid-template-columns: 1fr 120px 1fr;
  align-items: center;
  transform: translateX(-100px);
  > p {
    :first-child {
    text-align: end;
    };
    :nth-child(2) {
      text-align: center;
    };
    :last-child {
      text-align: start;
    }
  }
`;

export const ScrollerBar = styled.div`
  height: 80vh;
  width: 2px;
  position: absolute;
  left: 2vw;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.primary};
  line-height: 20px;
  text-align: center;
  transition: 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  z-index: 2;

  p {
    color: ${({ theme }) => theme.white};
    font-size: 15px;
    z-index: 3;
  }
  
  ::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 55%;
    height: 30px;
    width: 2px;
    background: ${({ theme }) => theme.primary};
    transform-origin: top center;
    transform: translateX(-50%);
    z-index: -1;
  }

  :last-child {
    ::before {
      width: 0px;
      height: 0px;
    }
  }

  :nth-child(odd) {
    left: 30px;
    ::before {
      transform: translateX(-50%) rotate(55deg);
    }
    :hover {
      transform: scale(1.21);
      ::before {
        transform: translateX(-50%) rotate(55deg);
      }
    }
  }

  :nth-child(even) {
    left: 0px;
    ::before {
      transform: translateX(-50%) rotate(-55deg);
    }
    :hover {
      transform: scale(1.21);
      ::before {
        transform: translateX(-50%) rotate(-55deg);
      }
    }
  }
`;

export const StyledContainer = styled.div`
  gap: 10px;
  display: flex;
`;

export const HistoryActionsContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    > p {
      font-size: 20px;
    }
  }
`;

export const SummaryContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 50%;
  padding: 10px 0;
`;