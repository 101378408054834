import React, { memo } from 'react';
import styled from 'styled-components';
import { CheckboxProps } from '../helpers/Interfaces';

const StyledFrame = styled.label<{ htmlFor: any }>`
  position: relative;
  width: 32px;
  height: 32px;
  display: inline-block;
  background: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.black50};
  border-radius: 8px;
  transition: 0.2s ease-in;
  cursor: pointer;
  overflow: hidden;

  ::after {
    position: absolute;
    left: 4px;
    top: 4px;
    border-radius: 4px;
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: 0.2s ease-in;
    transform: scale(0);
  }
`;

const StyledCheckbox = styled.input <{ id: any, type: string, checked: boolean, onChange: any }>`
  display: none;

  :checked ~ ${StyledFrame} {
    background: ${({ theme }) => theme.white50};
    border-color: ${({ theme }) => theme.black50};
    
    ::after {
      background: ${({ theme }) => theme.primary};
      transform: scale(1);
    };
  };
`;

const Checkbox: React.FC<CheckboxProps> = ({ checked, setChecked, columnId }) => {
  return (
    <div>
      <StyledCheckbox id={columnId} type="checkbox" checked={checked || false} onChange={setChecked} />
      <StyledFrame htmlFor={columnId} />
    </div>
  );
};

function areEqual(prevProps: CheckboxProps, nextProps: CheckboxProps) {
  return prevProps.checked === nextProps.checked;
}

export default memo(Checkbox, areEqual);
