import styled, { css } from 'styled-components';

export const StyledContainer = styled.div<{ isSidebarVisible: boolean }>`
  position: relative;
  width: 2vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  border-right: 1px solid ${({ theme }) => theme.black100};
  transition: 0.3s ease-in-out;
  z-index: 98;
  background: ${({ theme }) => theme.black50};
  overflow-x: hidden;
  overflow-y: auto;

  ${({ isSidebarVisible }) => isSidebarVisible && css`
    width: 15vw;
    padding: 10px;
  `}
`;

export const StyledUserInfoContainer = styled.div<{ isSidebarVisible: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  transform: scaleX(0);
  transform-origin: center left;
  transition: 0.4s ease;

  ${({ isSidebarVisible }) => isSidebarVisible && css`
    transform: scaleX(1);  
    margin-bottom: 40px;
  `};
`;

export const StyledUserAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: url('https://i.stack.imgur.com/l60Hf.png');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
`;

export const StyledUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledUserInfoParagraph = styled.p<{ isName?: boolean }>`
  color: ${({ theme }) => theme.white25};
  
  ${({ isName }) =>
    isName && css`
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.white};
  `};
`;

export const StyledToggleButton = styled.button<{ isSidebarVisible: boolean }>`
  position: absolute;
  border-radius: 50%;
  padding: 5px;
  align-self: center;
  width: 30px;
  height: 30px;
  background: transparent;
  color: ${({ theme }) => theme.white25};
  z-index: 99;
  cursor: pointer;
  transform: rotate(180deg);
  transition: all 0.4s ease-in-out;

  :hover { 
    color: ${({ theme }) => theme.white};
    transform: scale(1.59) rotate(180deg);
  }

  ${({ isSidebarVisible }) => isSidebarVisible && css`
    align-self: flex-end;
    transform: rotate(0deg);

    :hover { 
    transform: scale(1.59) rotate(0deg);
    }
  `}
`;

export const SavedCasesContainer = styled.div<{ isHidden: boolean, arrayLen: number }>`
  overflow: hidden;
  max-height: ${({ arrayLen }) => arrayLen * 50 + 50}px;
  transition: 0.4s ease-in-out;

  ${({ isHidden }) => isHidden && css`
    max-height: 0px;
  `};
`;

export const ExpandArrow = styled.i<{ hidden: boolean }>`
  transform: translate(0deg);
  transition: 0.3s ease-in-out;

  ${({ hidden }) => hidden && css`
    transform: rotate(90deg);
  `};
`;
