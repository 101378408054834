import React, { useState, useEffect, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../ducks/modules/rootReducer';
import { Dropdown as DropdownPrimereact } from '../helpers/PrimereactComponents';
import { DropdownProps } from '../helpers/Interfaces';

const Dropdown: React.FC<DropdownProps> = ({ dropdownData, columnId, handleSelectChange, forcedValue }) => {
  const { dropdownsToChangeBecauseOfCheckbox } = useSelector((state: RootState) => state.columns);
  const [value, setValue] = useState<string | undefined>(forcedValue || "");

  useEffect(() => {
    dropdownsToChangeBecauseOfCheckbox.forEach((toChange: any) => {
      if (toChange.name === dropdownData.name && toChange.columnId === columnId && toChange.value === forcedValue) {
        setValue(toChange.value);
      } else {
        setValue(forcedValue);
      }
    });
  }, [dropdownsToChangeBecauseOfCheckbox, columnId, dropdownData, forcedValue]);
  useEffect(() => {
    setValue(forcedValue);
  }, [forcedValue]);

  const handleChange = useCallback((e: any) => {
    const { name, value } = e.target;
    handleSelectChange({ columnId, name, value });

    setValue(e.target.value);
  }, [handleSelectChange, setValue, columnId]);

  return (
    <DropdownPrimereact
      name={dropdownData.name}
      options={dropdownData.options}
      value={value}
      onChange={handleChange}
    />
  );
};

function areEqual(prevProps: DropdownProps, nextProps: DropdownProps) {
  return prevProps.columnId === nextProps.columnId && prevProps.forcedValue === nextProps.forcedValue;
}

export default memo(Dropdown, areEqual);
