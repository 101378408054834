import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { AssumedHistory, HistoryListProps } from '../helpers/Interfaces';
import moment from 'moment';
import weatherRender from '../utils/weatherRender';

const SelectedList = styled.div<{ isListHidden: boolean }>`
  position: absolute;
  bottom: 5%;
  right: 0;
  background: white;
  width: 450px;
  height: 300px;
  box-shadow: 0px 0px 18px 0px ${({ theme }) => theme.black50};
  transform: translate(0, -50%);
  transition: 0.4s ease-in-out;
  ${({ isListHidden }) => isListHidden && css`
    transform: translate(100%, -50%);
    box-shadow: 0px 0px 0px 0px ${({ theme }) => theme.black50};
  `};
`;

const ListButton = styled.div<{ isListHidden: boolean }>`
  position: absolute;
  left: -35px;
  bottom: 0%;
  transform: translate(50%, -50%) rotate(0deg);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.black};
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  transition: 0.2s ease-out;
  ${({ isListHidden }) => isListHidden && css`
    transform: translate(0%, -50%) rotate(180deg);
  `};
`;

const HeaderContainer = styled.div`
  height: 60px;
  width: 100%;
  padding: 0 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.black50};
  position: relative;
`;

const HeaderIndicator = styled.div<{ offsetLeft: number, width: number, showingSelected: boolean }>`
  position: absolute;
  top: 0;
  left: ${({ offsetLeft }) => offsetLeft}px;
  width: ${({ width }) => width}px;
  height: 100%;
  border-radius: 12px;
  background: ${({ theme }) => theme.secondary};
  transition: 0.3s ease-in;
  transform :${({ showingSelected }) => `translateX(${showingSelected ? 0 : 100}%)`};
  z-index: 1;
`;

const HeaderItem = styled.div<{ showingSelected: boolean }>`
  width: 45%;
  padding: 10px;
  text-align: center;
  z-index: 2;
  font-size: 18px;
  cursor: pointer;

  ${({ showingSelected }) => showingSelected && css`
    font-size: 16px;
    font-weight: bold;
  `};
`;

const ListContainer = styled.div`
  width: 450px;
  height: 240px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid ${({ theme }) => theme.black50};
  overflow: hidden;
`;

const StyledList = styled.div<{ showingSelected: boolean }>`
  height: 240px;
  width: 450px;
  
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateX(-100%);
  transition: 0.3s ease-in-out;

  ${({ showingSelected }) => showingSelected && css`
    transform: translateX(0%);
  `};
`;

const StyledListItem = styled.li`
  width: 100%;
  height: 50px;
  line-height: 50px;
  list-style: none;
  border-top: 1px solid ${({ theme }) => theme.black50};

  :nth-child(even) {
    background: ${({ theme }) => theme.white25};
  }
`;

const HistoryList: React.FC<HistoryListProps> = ({ isListHidden, toggleFunction, assumedHistory, selectedToGenerate }) => {
  const [showingSelected, setShowingSelected] = useState<boolean>(true);
  const firstHeader = useRef<HTMLDivElement>(null);

  function compareTimestamp(a: AssumedHistory, b: AssumedHistory) {
    if (moment.duration(moment(a.firstEvent.timestamp).format("HH:mm")).asMinutes() < moment.duration(moment(b.firstEvent.timestamp).format("HH:mm")).asMinutes()) {
      return -1;
    }
    if (moment.duration(moment(a.firstEvent.timestamp).format("HH:mm")).asMinutes() > moment.duration(moment(b.firstEvent.timestamp).format("HH:mm")).asMinutes()) {
      return 1;
    }
    return 0;
  }

  return (
    <SelectedList isListHidden={isListHidden} >
      <ListButton isListHidden={isListHidden} onClick={toggleFunction}>
        <i className="pi pi-chevron-right" />
      </ListButton>
      <HeaderContainer>
        <HeaderIndicator offsetLeft={firstHeader.current ? firstHeader.current.offsetLeft : 0} width={firstHeader.current ? firstHeader.current.offsetWidth : 0} showingSelected={showingSelected} />
        <HeaderItem showingSelected={showingSelected} onClick={() => setShowingSelected(true)} ref={firstHeader} >Show only selected trips</HeaderItem>
        <HeaderItem showingSelected={!showingSelected} onClick={() => setShowingSelected(false)}>Show selected day's trips</HeaderItem>
      </HeaderContainer>
      <ListContainer>
        <StyledList showingSelected={showingSelected}>
          {selectedToGenerate.length ? selectedToGenerate.map((historyObject: AssumedHistory) =>
            <StyledListItem key={historyObject._id}>
              {`${weatherRender(historyObject.caseType)} ${moment(historyObject.firstEvent.timestamp).format("HH:mm")} - ${moment(historyObject.lastEvent.timestamp).format("HH:mm")} ${historyObject.firstEvent.stationName} - ${historyObject.lastEvent.stationName}`}
            </StyledListItem>
          ) : "0 trips selected"}
        </StyledList>
        <StyledList showingSelected={showingSelected}>
          {selectedToGenerate.length ? assumedHistory
            .filter((historyObject: AssumedHistory) => moment(moment(selectedToGenerate[0].firstEvent.timestamp).format("YYYY-MM-DD")).isSame(moment(historyObject.firstEvent.timestamp).format("YYYY-MM-DD")))
            .sort(compareTimestamp)
            .map((historyObj: AssumedHistory) =>
              <StyledListItem key={historyObj._id}>
                {`${weatherRender(historyObj.caseType)} ${moment(historyObj.firstEvent.timestamp).format("HH:mm")} - ${moment(historyObj.lastEvent.timestamp).format("HH:mm")} ${historyObj.firstEvent.stationName} - ${historyObj.lastEvent.stationName}`}
              </StyledListItem>
            ) : "0 trips selected"}
        </StyledList>
      </ListContainer>
    </SelectedList>
  );
}

export default HistoryList;
