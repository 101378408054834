import styled from 'styled-components';
import { appear } from '../helpers/keyframes';
import { InputTextarea, Button, Dropdown } from '../helpers/PrimereactComponents';

export const Container = styled.div`
  margin: 60px;
`;

export const VersionsContainer = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  padding: 40px;
  background: ${({ theme }) => theme.white};
  animation: ${appear} 0.3s ease-in-out;

  h1 {
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export const StyledTextarea = styled(InputTextarea)`
  min-height: 40px;
  min-width: 150px;
  max-height: 250px;
  max-width: 400px;
`;

export const StyledFlex = styled.div<{forButtons?: boolean}>`
  display: flex;
  gap: 10px;
  width: 100%;
  height: ${({ forButtons }) => forButtons ? '40px' : 'auto'};
  justify-content: ${({ forButtons }) => forButtons ? 'center' : 'space-around'};
  align-items: ${({ forButtons }) => forButtons ? 'stretch' : 'center'} ;
  margin: 20px;
`;

export const BackButton = styled(Button)`
  position: absolute;
  top: 5px;
  left: 5px;
`;

export const StyledDropdown = styled(Dropdown)`
  height: 30px;
  min-width: 140px;
`;