import React, { useState } from 'react';
import { APIService } from '../services/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../ducks/modules/rootReducer";
import { setLoadingAction } from '../ducks/modules/usersReducer';

import moment from 'moment';
import { toast } from 'react-toastify';
import { GenerateModalProps, Leg, PanelEventsTimelineResponse, Stop, ColumnInterface } from '../helpers/Interfaces';
import { convertStringToBooleans, generateEvents, generateSunnyCase, } from '../utils';

import { InputNumber } from '../helpers/PrimereactComponents';
import { StyledButton } from '../styles/PanelStyles';
import Modal from './Modal';


const GenerateModal: React.FC<GenerateModalProps> = ({ selectedCase, fetchedData, selectedExisting, createTimestamp, setTodaysTrips, selectedDate, closeModalFc }) => {
  const dispatch = useDispatch();

  const { loggedUser } = useSelector((state: RootState) => state.users);
  const [assumedPrice, setAssumedPrice] = useState<number>(0);

  const onClickGenerate = async () => {
    if (assumedPrice > 0 && selectedCase) {
      const caseId = selectedCase?._id === 'sunny_case' ? null : selectedCase?._id;
      let routeDetails = fetchedData;
      let allStations = fetchedData?.trips[0].legs.flatMap((leg: Leg) => [
        ...leg.stops.map((stop: Stop) => ({ ...stop, trainId: leg.number })),
      ]).filter((station: Stop) => station.departureDateTime || station.arrivalDateTime);
      if (selectedExisting && selectedCase && fetchedData) {
        dispatch(setLoadingAction(true));
        const run = async () => {
          const createArray = async () => {
            if (selectedCase?._id === 'sunny_case') {
              return generateSunnyCase(allStations.length * 9 - 3, routeDetails, allStations);
            } else if (selectedCase?._id) {
              return await APIService.get(`/cases/${selectedCase?._id}`).then(response => convertStringToBooleans(response.data));
            }
          }
          await generateEvents(caseId, routeDetails, allStations, dispatch, loggedUser, await createArray() as ColumnInterface[], assumedPrice);
          setAssumedPrice(0);
          await APIService.get(`events/timeline/${createTimestamp()}`).then((response => setTodaysTrips(response.data.map((trip: PanelEventsTimelineResponse) => ({
            id: trip._id,
            firstStationName: trip.firstEvent.stationName,
            lastStationName: trip.lastEvent.stationName,
            start_time: moment(trip.firstEvent.timestamp).format("DD-MM-YYYY HH:mm"),
            end_time: moment(trip.lastEvent.timestamp).format("DD-MM-YYYY HH:mm"),
            endToStart: moment.duration(moment(trip.lastEvent.timestamp).diff(moment(trip.firstEvent.timestamp))).asMinutes(),
            startTo00: moment.duration(moment(trip.firstEvent.timestamp).diff(moment(moment(new Date(selectedDate)).format("YYYY-MM-DD")))).asMinutes(),
          }))))).catch(err => console.log(err))
          dispatch(setLoadingAction(false));
          closeModalFc();
        }
        await run();
      };
    } else {
      toast.warning("Type in correct assumed price");
    }
  };

  return (
    <Modal header="Type in assumed cost" closeModalFunction={closeModalFc}>
      <div>
        <InputNumber inputStyle={{ width: '140px' }} value={assumedPrice} onValueChange={(e: any) => setAssumedPrice(e.target.value)} mode="currency" currency="EUR" locale="de-DE" />
        <StyledButton inModal label="Generate" onClick={onClickGenerate} />
      </div>
    </Modal>
  );
};

export default GenerateModal;