import React, { useState, useCallback, useEffect } from "react";
import { APIService } from '../services/ApiService';
import { StationDetails, PanelSavedCase, PanelSelectedExisting, PanelTodaysTrips, PanelEventsTimelineResponse } from '../helpers/Interfaces';
import moment from 'moment';
import PanelSelectors from '../components/PanelSelectors';
import CasesTable from '../components/CasesTable';
import TripfinderTimeline from '../components/Timelines/TripfinderTimeline';
import DailyTimeline from '../components/Timelines/DailyTimeline';
import GenerateModal from '../components/GenerateModal';
import { Tooltip } from '../helpers/PrimereactComponents';
import { Container, Header, Splitter, FormContainer, StyledButton, GridContainer, TablesContainer, StyledInfo } from '../styles/PanelStyles';

const Panel: React.FC = () => {
  const [firstStation, setFirstStation] = useState<StationDetails | null>(null);
  const [lastStation, setLastStation] = useState<StationDetails | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<Date>(new Date());
  const [isGeneratingNew, setIsGeneratingNew] = useState<boolean>(false);
  const [selectedExisting, setSelectedExisting] = useState<PanelSelectedExisting | null>();
  const [selectedSavedJourneyCases, setSelectedSavedJourneyCases] = useState<PanelSavedCase[]>([]);
  const [selectedCase, setSelectedCase] = useState<PanelSavedCase | undefined>();
  const [savedJourneys, setSavedJourneys] = useState<PanelSelectedExisting[]>([]);
  const [fetchedData, setFetchedData] = useState<any>(null);
  const [todaysTrips, setTodaysTrips] = useState<PanelTodaysTrips[]>([]);
  const [journeysToDisplay, setJourneysToDisplay] = useState<PanelSelectedExisting[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (selectedExisting) {
      setSelectedSavedJourneyCases([{ caseName: 'Sunny Case', _id: 'sunny_case', caseType: 'SUNNY' }, ...selectedExisting?.cases]);
      setSelectedCase({ caseName: 'Sunny Case', _id: 'sunny_case', caseType: 'SUNNY' });
    }
  }, [selectedExisting]);

  useEffect(() => {
    APIService.get(`events/timeline/${createTimestamp()}`).then((response => setTodaysTrips(response.data.map((trip: PanelEventsTimelineResponse) => ({
      id: trip._id,
      firstStationName: trip.firstEvent.stationName,
      lastStationName: trip.lastEvent.stationName,
      start_time: moment(trip.firstEvent.timestamp).format("DD-MM-YYYY HH:mm"),
      end_time: moment(trip.lastEvent.timestamp).format("DD-MM-YYYY HH:mm"),
      endToStart: moment.duration(moment(trip.lastEvent.timestamp).diff(moment(trip.firstEvent.timestamp))).asMinutes(),
      startTo00: moment.duration(moment(trip.firstEvent.timestamp).diff(moment(moment(new Date(selectedDate)).format("YYYY-MM-DD")))).asMinutes(),
    }))))).catch(err => console.log(err));
    // eslint-disable-next-line
  }, [selectedDate, fetchedData]);

  useEffect(() => {
    APIService.get('/journeys/existing/cases')
      .then((response) => {
        setSavedJourneys(response.data.map((journeyCase: PanelSelectedExisting) => ({ ...journeyCase, toFilter: `${journeyCase.firstStation} - ${journeyCase.lastStation}` })))
      }).catch((err) => console.log(err));
  }, [])

  const createTimestamp = useCallback(() => {
    const date = moment(selectedDate).format("YYYY-MM-DD");
    const time = moment(selectedTime).add(1, 'hours').format("HH:mm:ss");
    return moment(`${date} ${time}`).format("YYYY-MM-DDTHH:mm:ss");
  }, [selectedDate, selectedTime]);

  const onClickSwitch = useCallback(() => {
    if (isGeneratingNew) {
      setFirstStation(null);
      setLastStation(null);
    } else {
      setSelectedExisting(null);
    }
    setIsGeneratingNew(!isGeneratingNew);
    setFetchedData(null);
  }, [isGeneratingNew])

  return (
    <>
      <Container>
        <FormContainer>
          <Header smaller>{isGeneratingNew ? 'Create new route:' : 'Choose existing route'}</Header>
          <GridContainer isGeneratingNew={isGeneratingNew}>
            <PanelSelectors
              isGeneratingNew={isGeneratingNew}
              firstStation={firstStation}
              setFirstStation={setFirstStation}
              lastStation={lastStation}
              setLastStation={setLastStation}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
              fetchedData={fetchedData}
              setIsModalOpen={setIsModalOpen}
              selectedExisting={selectedExisting}
              createTimestamp={createTimestamp}
              selectedCase={selectedCase}
              savedJourneys={savedJourneys}
              setJourneysToDisplay={setJourneysToDisplay}
              setFetchedData={setFetchedData}
            />
          </GridContainer>
          <Splitter>
            <Tooltip target=".info" mouseTrack position="left" />
            <StyledInfo
              className="info"
              data-pr-tooltip="Note that presence data log uses more time than the trip itself, since there are events before first station and after last station"
            >?</StyledInfo>
          </Splitter>
          <Header smallest>Selected date's trips</Header>
          <DailyTimeline data={todaysTrips} />
          {fetchedData ?
            <>
              <Splitter />
              <Header smallest>Selected trip</Header>
              <TripfinderTimeline data={fetchedData.trips[0]} />
            </> : fetchedData === null ? null : <p style={{ 'color': 'red' }}>Journey not found</p>}
          <Splitter />
          {!isGeneratingNew && <TablesContainer selectedExisting={selectedExisting}>
            <CasesTable journeysToDisplay={journeysToDisplay} selectedExisting={selectedExisting} setSelectedExisting={setSelectedExisting} selectedSavedJourneyCases={selectedSavedJourneyCases} selectedCase={selectedCase} setSelectedCase={setSelectedCase} />
          </TablesContainer>}
          <StyledButton swapping="true" label={isGeneratingNew ? "Go to saved journeys" : "Create new journey"} onClick={onClickSwitch} />
        </FormContainer>
      </Container>
      {isModalOpen && <GenerateModal
        selectedCase={selectedCase}
        fetchedData={fetchedData}
        selectedExisting={selectedExisting}
        createTimestamp={createTimestamp}
        setTodaysTrips={setTodaysTrips}
        selectedDate={selectedDate}
        closeModalFc={() => setIsModalOpen(false)}
      />}
    </>
  );
};

export default Panel;
