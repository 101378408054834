import React, { useState, useRef, useEffect, useCallback } from 'react';
import { APIService } from '../services/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../ducks/modules/rootReducer';
import { setMultiGeneratingLoadingAction, setLoaderProgressAction } from '../ducks/modules/usersReducer';
import { Stop, Leg, AssumedHistory } from '../helpers/Interfaces';

import moment from 'moment';
import { toast } from 'react-toastify';
import { convertStringToBooleans, generateSunnyCase, generateEvents } from '../utils'

import { Button, Calendar, Tooltip } from '../helpers/PrimereactComponents';
import HistoryTimeline from '../components/Timelines/HistoryTimeline/HistoryTimeline';
import HistoryList from '../components/HistoryList';
import HistorySummary from '../components/HistorySummary';
import { Container, ContentContainer, Header, ScrollerBar, Dot, StyledContainer, HistoryActionsContainer, SummaryContainer } from '../styles/HistoryStyles';

const History: React.FC = () => {
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((state: RootState) => state.users);
  const containerRef = useRef<HTMLDivElement>(null);

  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [assumedHistory, setAssumedHistory] = useState([]);
  const [dataToSummary, setDataToSummary] = useState<AssumedHistory[]>([]);
  const [calculatedHistory, setCalculatedHistory] = useState([]);
  const [selectedToGenerate, setSelectedToGenerate] = useState<AssumedHistory[]>([]);
  const [selectedFirstTripsDate, setSelectedFirstTripsDate] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isListHidden, setIsListHidden] = useState<boolean>(true);
  const [displayHoursIndex, setDisplayHoursIndex] = useState(2);

  const changeScale = (direction: number) => {
    if ((direction < 0 && displayHoursIndex > 0) || (direction > 0 && displayHoursIndex < DISPLAY_HOURS.length - 1)) {
      if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollTop * DISPLAY_HOURS[displayHoursIndex] / DISPLAY_HOURS[displayHoursIndex + direction];
      return setDisplayHoursIndex(displayHoursIndex + direction)
    }
  };

  const todaysDate = moment().add(1, 'd').startOf("day");

  const DISPLAY_HOURS = [2, 3, 4, 6, 8, 12];
  const hoursToDisplay = [[24, 22, 20, 18, 16, 14, 12, 10, 8, 6, 4, 2], [24, 21, 18, 15, 12, 9, 6, 3], [24, 20, 16, 12, 8, 4], [24, 18, 12, 6], [24, 16, 8], [24, 12]];

  useEffect(() => {
    let isMounted = true;
    setContainerHeight(containerRef.current ? containerRef?.current?.offsetHeight : 0);
    fetchData(isMounted);
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    if (selectedToGenerate.length) {
      setSelectedFirstTripsDate(moment(selectedToGenerate[0].firstEvent.timestamp).format("YYYY-MM-DD"));
    } else {
      setSelectedFirstTripsDate("");
    }
  }, [selectedToGenerate]);

  const fetchData = async (isMounted: boolean) => {
    await APIService.get('/history/statuses').then((response) => isMounted ? setDataToSummary(response.data) : null);
    await APIService.get('/events/history/list/saved').then((response) => isMounted ? setAssumedHistory(response.data) : null);
    await APIService.get('/events/history/list/users').then((response) => isMounted ? setCalculatedHistory(response.data) : null);
  }

  const handleDateChange = useCallback(({ value }: any) => {
    setSelectedDate(value);
  }, [setSelectedDate]);

  const scrollTo = (day: number) => {
    if (containerRef.current) containerRef.current.scrollTop = day * containerHeight * (24 / DISPLAY_HOURS[displayHoursIndex]);
  }

  const generateTimestamp = () => todaysDate.subtract(1, 'd').format("DD-MM-YYYY")

  const createTimestampForGenerating = (dateTimestamp?: Date, timeTimestamp?: string) => {
    const date = moment(dateTimestamp).format("YYYY-MM-DD");
    const time = moment(timeTimestamp).add(1, 'h').format("HH:mm:ss");
    return moment(`${date} ${time}`).format("YYYY-MM-DDTHH:mm:ss");
  };

  const multiRerun = async () => {
    let iterator = 0;
    dispatch(setLoaderProgressAction(`Generating ${iterator + 1} / ${selectedToGenerate.length}`));
    dispatch(setMultiGeneratingLoadingAction(true));
    selectedToGenerate.forEach(async (selected: any, i: number) => {
      await generate(selected);
      iterator++;
      dispatch(setLoaderProgressAction(`Generating ${iterator + 1} / ${selectedToGenerate.length}`))
      if (iterator === selectedToGenerate.length) {
        dispatch(setMultiGeneratingLoadingAction(false));
      }
    });
  }

  const rerunFromSpecificDay = async () => {
    const selectedDays = assumedHistory.filter((historyObject: AssumedHistory) => moment(selectedFirstTripsDate).isSame(moment(historyObject.firstEvent.timestamp).format("YYYY-MM-DD")))
    let iterator = 0;
    dispatch(setLoaderProgressAction(`Generating ${iterator + 1} / ${selectedToGenerate.length}`));
    dispatch(setMultiGeneratingLoadingAction(true));
    selectedDays.forEach((selected: any, i: number) => {
      setTimeout(async () => {
        dispatch(setLoaderProgressAction(`Generating ${iterator++} / ${selectedDays.length}`))
        await generate(selected)
        if (iterator === selectedDays.length) {
          dispatch(setMultiGeneratingLoadingAction(false));
        }
      }, i * 1500);
    })
  };

  const generate = async (historyObject: any) => {
    const fetchedData = await APIService.post(`journeys/${historyObject.firstEvent.stationId}/${historyObject.lastEvent.stationId}`, {
      firstStationName: historyObject.firstEvent.stationName,
      lastStationName: historyObject.lastEvent.stationName,
      timestamp: createTimestampForGenerating(selectedDate, historyObject.firstEvent.timestamp)
    }).then((response) => {
      return response.data;
    }).catch(() => {
      toast.error(`No journey at ${createTimestampForGenerating(selectedDate, historyObject.firstEvent.timestamp)}`);
      return false;
    });
    if (fetchedData) {
      let routeDetails = fetchedData;
      let allStations = fetchedData?.trips[0].legs.flatMap((leg: Leg) => [
        ...leg.stops.map((stop: Stop) => ({ ...stop, trainId: leg.number })),
      ]).filter((station: Stop) => station.departureDateTime || station.arrivalDateTime);
      const run = async () => {
        const createArray = async () => {
          if (historyObject.journeyCaseId === null) {
            return generateSunnyCase(allStations.length * 9 - 3, routeDetails, allStations);
          } else {
            return await APIService.get(`/cases/${historyObject.journeyCaseId}`).then(response => convertStringToBooleans(response.data));
          }
        }
        await generateEvents(historyObject.journeyCaseId, routeDetails, allStations, dispatch, loggedUser, await createArray(), historyObject.assumedCost / 100);
      }
      await run();
    }
    await fetchData(true);
  }

  return (
    <Container>
      <Tooltip target=".dot" mouseTrack />
      <Header>
        <p>Calculated price</p>
        <p>Case type</p>
        <p>Assumed price</p>
      </Header>
      <ContentContainer ref={containerRef}>
        <ScrollerBar>{[...Array(30)].map((_: any, i: number) =>
          <Dot key={i} className="dot" data-pr-tooltip={generateTimestamp()} onClick={() => scrollTo(i)}><p>{moment().subtract(i, 'd').format("DD")}</p></Dot>
        )}</ScrollerBar>
        <HistoryTimeline
          containerHeight={containerHeight}
          assumedHistory={assumedHistory}
          calculatedHistory={calculatedHistory}
          setSelectedToGenerate={setSelectedToGenerate}
          selectedToGenerate={selectedToGenerate}
          selectedFirstTripsDate={selectedFirstTripsDate}
          displayHours={DISPLAY_HOURS[displayHoursIndex]}
          hoursToDisplay={hoursToDisplay[displayHoursIndex]}
          setDataToSummary={(summaryData: AssumedHistory[]) => setDataToSummary(summaryData)}
        />
      </ContentContainer>
      <HistoryList isListHidden={isListHidden} toggleFunction={() => setIsListHidden(!isListHidden)} assumedHistory={assumedHistory} selectedToGenerate={selectedToGenerate} />
      <HistoryActionsContainer>
        <h4>Hours per screen scale</h4>
        <div>
          <Button onClick={() => changeScale(-1)} label="-" />
          <p>{DISPLAY_HOURS[displayHoursIndex]}</p>
          <Button onClick={() => changeScale(1)} label="+" />
        </div>
        <StyledContainer>
          <Tooltip target=".btn" position="top" />
          <div
            className="btn"
            data-pr-tooltip="Select a single trip and rerun every from that day"
          >
            <Button
              disabled={!selectedToGenerate.length}
              label="Rerun whole day"
              onClick={() => rerunFromSpecificDay()}
            />
          </div>
          <Calendar dateFormat="dd/mm/yy" value={selectedDate} onChange={handleDateChange} placeholder="Pick date to generate" style={{ 'maxWidth': '110px' }} />
          <div
            className="btn"
            data-pr-tooltip="Select trips you want to rerun"
          >
            <Button
              className="btn"
              disabled={!selectedToGenerate.length}
              label="Rerun selected trips"
              onClick={() => multiRerun()}
            />
          </div>
        </StyledContainer>
      </HistoryActionsContainer>
      <SummaryContainer>
        <HistorySummary atHistory dataToSummary={dataToSummary} />
      </SummaryContainer>
    </Container>
  );
};

export default History;
