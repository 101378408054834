import React from 'react';
import styled from 'styled-components';
import { scaleLoader } from '../helpers/keyframes';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999999;
`;

const Loader = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: ${scaleLoader} 1s ease-in-out both infinite;
  text-align: center;
  font-size: 64px;

  p {
    font-size: 12px;
  }
`;

const Loading: React.FC<{ content?: string }> = ({ content }) =>
  <Container>
    <Loader><span role="img" aria-label="train">🚇</span><p>loading</p><p>{content}</p></Loader>
  </Container>;

export default Loading;