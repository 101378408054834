import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./views/Login";
import Panel from "./views/Panel";
import Trip from "./views/Trip";
import History from './views/History';
import Versions from './views/Versions';
import { Provider } from "react-redux";
import store from "./ducks/store";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import LayoutTemplate from "./templates/LayoutTemplate";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <LayoutTemplate>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/panel" component={Panel} />
            <Route exact path="/history" component={History} />
            <Route path="/trip/history" component={Trip} />
            <Route path="/trip" component={Trip} />
            <Route path="/versions" component={Versions} />
            <Route component={Panel} />
          </Switch>
        </LayoutTemplate>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
