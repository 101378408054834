import moment from 'moment';
import { DownloadDataInterface} from '../helpers/Interfaces';

const downloadJsonFile = (events: {events: DownloadDataInterface[], externalServerResponseStatusCode: number}) => {
  const fileName = moment().format('DD-MM-YYYY-HH-mm-ss');
  const json = JSON.stringify(events);
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default downloadJsonFile;