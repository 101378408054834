import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from '../../helpers/PrimereactComponents';
import { Stop } from '../../helpers/Interfaces';
import moment from 'moment';

const Container = styled.div`
  width: 100%;
  padding: 0px 20px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.white50};
`;

const DotsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: -12px;
`;

const TimelinePoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${({ theme }) => theme.white};

  :first-child {
    align-items: flex-start;
  }
`;

const TimelineDot = styled.div<{ changeStation?: boolean }>`
  background: ${({ theme }) => theme.white50};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;

  ${({ changeStation }) => changeStation && css`
    width: 30px;
    height: 30px;
    margin-top: -5px;
  `};
`;

const FullRouteTimeline: React.FC<{ data: Stop[], onClickDot(stationIndex: number): void }> = ({ data, onClickDot }) => {

  const generateTooltip = ({ name, code, arrivalDateTime, departureDateTime }: Stop) => {
    let time;
    if (arrivalDateTime && departureDateTime) {
      time = `${moment(arrivalDateTime).format("HH:mm")} - ${moment(departureDateTime).format("HH:mm")}`;
    } else {
      time = moment(arrivalDateTime || departureDateTime).format("HH:mm");
    }

    return `${name}\n${code}\n${time}`;
  }

  return (
    <Container>
      <Line />
      <DotsContainer>
        {/* <Tooltip target=".dot" mouseTrack /> */}
        {data.length ? data.map((station: Stop, index: number) => {
          if (index === 0 || index === data.length - 1) {
            return (
              <div key={index}>
                <TimelinePoint>
                  <TimelineDot
                    changeStation
                    className="dot"
                    data-pr-tooltip={generateTooltip(station)}
                    onClick={() => onClickDot(index)}
                  />
                  <p>{station.name}</p>
                </TimelinePoint>
              </div>
            )
          } else {
            return (
              <div key={index}>
                <Tooltip target=".dot" position="bottom" />
                <TimelinePoint>
                  <TimelineDot
                    className="dot"
                    data-pr-tooltip={generateTooltip(station)}
                    onClick={() => onClickDot(index)}
                    changeStation={station?.changeStation}
                  />
                </TimelinePoint>
              </div>
            )
          }
        }) : ""}
      </DotsContainer>
    </Container>
  );
};

export default FullRouteTimeline;
