import Keycloak from "keycloak-js";

const _kc = new Keycloak({
  url: "https://idp.innokrea.dev/auth",
  realm: "cibo",
  clientId: "cibo-web",
});

const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "login-required",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        console.warn("not authenticated!");
        doLogin();
      }
    });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const updateToken = (successCallback) => {
  return _kc.updateToken(5).then(successCallback).catch(doLogin);
};

const getUsername = () => _kc.tokenParsed.preferred_username;

const getUser = () => _kc.tokenParsed;

export default {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  updateToken,
  getUsername,
  getUser,
};
