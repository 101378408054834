import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { APIService } from '../../services/ApiService';
import { toast } from 'react-toastify';
import { PanelSavedCase } from '../../helpers/Interfaces';

interface StateInterface {
  loggedUser: string;
  version: string;
  selectedEndpoint: string;
  usersCases: [];
  loading: boolean;
  multiGeneratingLoading: boolean;
  loaderProgress: string;
};

let initialState: StateInterface = {
  loggedUser: localStorage.getItem("test-tool-username") || "",
  version: "",
  selectedEndpoint: localStorage.getItem("test-tool-endpoint") || "",
  usersCases: [],
  loading: false,
  multiGeneratingLoading: false,
  loaderProgress: "",
};

const usersDetails = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoggedUserData(state, action: PayloadAction<any>) {
      state.loggedUser = action.payload;
    },
    setUsersCases(state, action: PayloadAction<any>) {
      state.usersCases = action.payload;
    },
    setLoading(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    setMultiGeneratingLoading(state, action: PayloadAction<any>) {
      state.multiGeneratingLoading = action.payload;
    },
    setLoaderProgress(state, action: PayloadAction<any>) {
      state.loaderProgress = action.payload;
    },
    setEndpoint(state, action: PayloadAction<any>) {
      state.selectedEndpoint = action.payload;
    },
    setVersion(state, action: PayloadAction<any>) {
      state.version = action.payload;
    }
  },
});

export const {
  setLoggedUserData,
  setEndpoint,
  setUsersCases,
  setLoading,
  setMultiGeneratingLoading,
  setLoaderProgress,
  setVersion,
} = usersDetails.actions;

export const setLoggedUserDataAction = (
  loggedUser?: string,
): AppThunk => async (dispatch) => {
  if (!loggedUser) {
    localStorage.removeItem("test-tool-username");
    localStorage.removeItem("test-tool-token");
  }
  try {
    dispatch(setLoggedUserData(loggedUser));
  } catch (error) {
    toast.error(error.response ? error.response.data.message : "");
  };
};

export const setSelectedEndpointAction = (
  endpoint: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(setEndpoint(endpoint))
  } catch (err) {
    console.log(err);
  }
}

export const setUsersCasesAction = (
  journeyBaseId?: string,
): AppThunk => async (dispatch, getState) => {
  try {
    const user = getState().users.loggedUser;
    if(journeyBaseId) {
      await APIService.get(`/cases/journeys/${journeyBaseId}/users/${user}`).then(({ data }: {data: PanelSavedCase[]}) => {
        dispatch(setUsersCases(data))});  
    }  
  } catch (error) {
    toast.error(error.response ? error.response.data.message : "");
  }
}

export const setLoadingAction = (value: boolean): AppThunk => async (dispatch) => {
  dispatch(setLoading(value));
}

export const setMultiGeneratingLoadingAction = (value: boolean): AppThunk => async (dispatch) => {
  dispatch(setMultiGeneratingLoading(value));
}

export const setLoaderProgressAction = (value: string): AppThunk => async (dispatch) => {
  dispatch(setLoaderProgress(value));
}
export const setVersionAction = (value: string): AppThunk => async (dispatch) => {
  dispatch(setVersion(value));
}

export default usersDetails.reducer;
