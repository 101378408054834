import { combineReducers } from "@reduxjs/toolkit";
import columnsReducer from "./columnsReducer";
import usersReducer from "./usersReducer";

const rootReducer = combineReducers({
  columns: columnsReducer,
  users: usersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
