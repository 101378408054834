import styled, { css } from "styled-components";
import { Button, Dropdown } from '../helpers/PrimereactComponents';

export const Container = styled.div`
  position: relative;
  transition: 0.4s ease;
  min-width: 80vw;
  margin-top: 5vh;
`;

export const Header = styled.h1<{ smaller?: boolean, smallest?: boolean }>`
  margin: 40px 60px;

  ${({ smaller }) => smaller && css`
    margin: 10px 0;
    text-align: center;
  `};

  ${({ smallest }) => smallest && css`
    margin: 10px 0;
    text-align: center;
    font-size: 20px;
  `};
`;

export const Splitter = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.white25};
  margin: 15px 0;
  position: relative;
`;

export const FormContainer = styled.div`
  min-height: 300px;
  min-width: 80vw;
  max-width: 85vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  background: ${({ theme }) => theme.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.4s ease;
`;

export const StyledButton = styled(Button)<{ swapping?: boolean, inModal?: boolean }>`
  max-width: 150px;
  margin: 5px;

  ${({ inModal }) => inModal && css`
    margin: 0 0 0 5px;
  `};

  ${({ swapping }) => swapping && css`
    margin-top: 20px;
  `};
`;

export const GridContainer = styled.div<{ isGeneratingNew: boolean }>`
  height: 60px;
  display: grid;
  grid-template-columns: 260px 120px 120px 120px 80px 120px 130px 120px;

  ${({ isGeneratingNew }) => isGeneratingNew && css`
    grid-template-columns: 310px 1px 310px 120px 80px 120px;
  `};
`;

export const Pointer = styled.div<{ brighter?: boolean }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${({ brighter, theme }) => brighter ? theme.secondary : theme.primary};
  color: ${({ theme }) => theme.white};
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  position: relative;
  margin: 0px 5px 5px 0px;

  ::before {
    content: '';
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid ${({ brighter, theme }) => brighter ? theme.secondary : theme.primary};
  }
`;

export const StyledRowFlex = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StyledSwap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.white25};
  background: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  z-index: 3;
  transform: translate(-50%, 50%);
  cursor: pointer;

  i {
    font-size: 10px;
    font-weight: 900;
    color: ${({ theme }) => theme.primary};

    :first-child {
      color: ${({ theme }) => theme.secondary};
    }
  }
`;

export const StyledDropdown = styled(Dropdown)`
  margin: 5px;
`;

export const TablesContainer = styled.div<{ selectedExisting: unknown }>`
  display: grid;
  grid-template-columns: ${({ selectedExisting }) => selectedExisting ? "0.69fr 0.29fr" : "1fr"};
  grid-gap: 30px;
  transition: 0.4s ease-in-out;
`;

export const StyledInfo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.primary};
  cursor: pointer;
`;