import React, { useState, useCallback, useEffect } from 'react';
import tripfinderData from '../helpers/tripfinderData.json';
import { useDispatch } from 'react-redux';
import { setLoadingAction } from '../ducks/modules/usersReducer';
import { useHistory } from "react-router-dom";
import { APIService } from '../services/ApiService';

import moment from 'moment';
import { toast } from 'react-toastify';

import casesTypes from '../helpers/casesTypes';
import { InputText, Calendar } from '../helpers/PrimereactComponents';
import { PanelSelectorsProps, StationDetails, PanelSelectedExisting, PanelSavedCase } from '../helpers/Interfaces';
import { StyledDropdown, StyledSwap, StyledButton, StyledRowFlex, Pointer } from '../styles/PanelStyles';

const PanelSelectors: React.FC<PanelSelectorsProps> = ({ isGeneratingNew, firstStation, setFirstStation, lastStation, setLastStation, selectedDate,
  setSelectedDate, selectedTime, setSelectedTime, fetchedData, setIsModalOpen, selectedExisting, createTimestamp, selectedCase, savedJourneys, setJourneysToDisplay, setFetchedData }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [filteringTripStations, setFilteringTripStations] = useState<string>("");
  const [connections, setConnections] = useState<number | undefined>(undefined);
  const [selectedCaseType, setSelectedCaseType] = useState<string | null>(null);
  const [bluredTime, setBluredTime] = useState<Date | null>(null);

  useEffect(() => {
    const filteredByConnections = connections ? savedJourneys.filter((journey: PanelSelectedExisting) => journey.connectionsCount >= connections) : savedJourneys;
    const filteredByStations = filteringTripStations ? filteredByConnections.filter((journey: PanelSelectedExisting) => journey.toFilter.toLowerCase().includes(filteringTripStations.toLowerCase())) : filteredByConnections;
    const filteredByCases = selectedCaseType ? filteredByStations.filter((journey: PanelSelectedExisting) => journey.cases.length > 0).filter((journ: PanelSelectedExisting) => journ.cases.some((kejs: PanelSavedCase) => kejs.caseType === selectedCaseType)) : filteredByStations;
    setJourneysToDisplay(selectedCaseType === 'SUNNY' || selectedCaseType === null ? filteredByStations : filteredByCases);
  }, [savedJourneys, connections, filteringTripStations, selectedCaseType, setJourneysToDisplay]);

  useEffect(() => {
    const getData = async () => {
      const now = moment().format("YYYY-MM-DD HH:mm");
      const fromPickers = moment(createTimestamp()).subtract(1, 'h').format("YYYY-MM-DD HH:mm");
      if (moment.duration(moment(now).diff(moment(fromPickers))).asMinutes() > 1) {

        if (selectedExisting && selectedTime && selectedDate) {
          dispatch(setLoadingAction(true));
          const firstStationCode = tripfinderData.find((obj: StationDetails) => obj.name === selectedExisting?.firstStation)?.code;
          const lastStationCode = tripfinderData.find((obj: StationDetails) => obj.name === selectedExisting?.lastStation)?.code;
          await APIService.post(`journeys/${firstStationCode}/${lastStationCode}`, {
            firstStationName: selectedExisting.firstStation,
            lastStationName: selectedExisting.lastStation,
            stationsCount: selectedExisting.stationsCount,
            timestamp: createTimestamp()
          }).then((response) => {
            setFetchedData(response.data);
          }).catch((error) => {
            error.response ?
              error.response.status === 409 ?
                toast.warning(error.response.data.message) : toast.error(error.response.data.message) : toast.error("Error");
            setFetchedData(false);
          });
        } else if (firstStation && lastStation && selectedTime && selectedDate) {
          dispatch(setLoadingAction(true));
          await APIService.post(`journeys/${firstStation.code}/${lastStation.code}`, {
            firstStationName: firstStation.name,
            lastStationName: lastStation.name,
            timestamp: createTimestamp()
          }).then((response) => {
            setFetchedData(response.data);
          }).catch(() => {
            setFetchedData(false);
          });
        }
        dispatch(setLoadingAction(false));
      }
    }
    getData();
    // eslint-disable-next-line
  }, [selectedExisting, firstStation, lastStation, selectedDate, bluredTime]);

  const onClickProceedNewRoute = useCallback(async () => {
    if (firstStation && lastStation && fetchedData) {
      history.push(`/trip?firstStationName=${firstStation.name}&lastStationName=${lastStation.name}&firstStationCode=${firstStation.code}&lastStationCode=${lastStation.code}&timestamp=${createTimestamp()}&caseId=sunny_case`);
    } else toast.warning("You need to pick first and last station");
  }, [firstStation, lastStation, history, fetchedData, createTimestamp]);

  const onClickProceedExistingRoute = useCallback(async () => {
    if (selectedExisting && selectedCase && fetchedData) {
      const firstStationCode = tripfinderData.find((obj: StationDetails) => obj.name === selectedExisting?.firstStation)?.code;
      const lastStationCode = tripfinderData.find((obj: StationDetails) => obj.name === selectedExisting?.lastStation)?.code;
      history.push(`/trip?firstStationName=${selectedExisting.firstStation}&lastStationName=${selectedExisting.lastStation}&firstStationCode=${firstStationCode}&lastStationCode=${lastStationCode}&timestamp=${createTimestamp()}&caseId=${selectedCase._id}`);
    } else toast.warning("You need to pick first and last station");
  }, [selectedExisting, selectedCase, history, fetchedData, createTimestamp]);

  const handleFirstStationChange = useCallback(({ value }: any) => {
    setFirstStation(value);
  }, [setFirstStation]);

  const handleLastStationChange = useCallback(({ value }: any) => {
    setLastStation(value);
  }, [setLastStation]);

  const handleDateChange = useCallback(({ value }: any) => {
    setSelectedDate(value);
  }, [setSelectedDate]);

  const handleTimeChange = useCallback(({ value }: any) => {
    setSelectedTime(value);
  }, [setSelectedTime]);

  const handleBluredTimeChange = useCallback(({ timeStamp }: any) => {
    setBluredTime(timeStamp);
  }, [setBluredTime]);

  const onClickCaseManaging = async () => {
    const firstStationCode = tripfinderData.find((obj: StationDetails) => obj.name === selectedExisting?.firstStation)?.code;
    const lastStationCode = tripfinderData.find((obj: StationDetails) => obj.name === selectedExisting?.lastStation)?.code;
    history.push(`/trip?firstStationName=${selectedExisting?.firstStation}&lastStationName=${selectedExisting?.lastStation}&firstStationCode=${firstStationCode}&lastStationCode=${lastStationCode}&timestamp=${selectedExisting?.journeyDate}&caseId=${selectedCase?._id}&case_editor=1`);
  }

  const swapStations = () => {
    setFirstStation(lastStation);
    setLastStation(firstStation);
  }

  const optionTemplateWithTag = (option: StationDetails) => <div><p>{option.name}</p><p>{option.tag}</p></div>;

  const valueTemplateFrom = (option: StationDetails) =>
    <StyledRowFlex>
      <Pointer>from</Pointer><p>{option ? option.name : 'Select first station'}</p>
    </StyledRowFlex>;

  const valueTemplateTo = (option: StationDetails) =>
    <StyledRowFlex>
      <Pointer brighter>to</Pointer><p>{option ? option.name : 'Select last station'}</p>
    </StyledRowFlex>;

  const filterTemplate = (option: { name: string; label: string }) => <StyledRowFlex>{option?.label}</StyledRowFlex>

  return (
    <>
      {isGeneratingNew ?
        <>
          <StyledDropdown
            value={firstStation}
            options={tripfinderData}
            optionLabel="name"
            onChange={handleFirstStationChange}
            filter
            showClear
            placeholder="Select first station"
            itemTemplate={optionTemplateWithTag}
            valueTemplate={valueTemplateFrom}
          />
          <StyledSwap onClick={swapStations}>
            <i className="pi pi-arrow-left" />
            <i className="pi pi-arrow-right" />
          </StyledSwap>
          <StyledDropdown
            value={lastStation}
            options={tripfinderData}
            optionLabel="name"
            onChange={handleLastStationChange}
            filter
            showClear
            placeholder="Select last station"
            itemTemplate={optionTemplateWithTag}
            valueTemplate={valueTemplateTo}
          />
        </> :
        <>
          <span className="p-float-label" style={{ 'margin': '5px' }}>
            <InputText id="stationsFilter" style={{ 'width': '100%', 'height': '100%' }} value={filteringTripStations} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilteringTripStations(e.target.value)} />
            <label htmlFor="stationsFilter">Stations filter</label>
          </span>
          <span className="p-float-label" style={{ 'margin': '5px' }}>
            <InputText id="connectionsFilter" style={{ 'width': '100%', 'height': '100%' }} type="number" min={0} value={connections} onChange={(e: any) => setConnections(e.target.value)} />
            <label htmlFor="connectionsFilter">Connections</label>
          </span>
          <span className="p-float-label" style={{ 'margin': '5px' }}>
            <StyledDropdown id="casesFilter" style={{ 'width': '100%', 'height': '100%', 'margin': '0px' }} options={casesTypes} value={selectedCaseType} onChange={(e: any) => setSelectedCaseType(e.value)} itemTemplate={filterTemplate} valueTemplate={filterTemplate} />
            <label htmlFor="casesFilter">Cases filter</label>
          </span>
        </>
      }
      <Calendar dateFormat="dd/mm/yy" style={{ 'margin': '5px' }} value={selectedDate} onChange={handleDateChange} />
      <Calendar style={{ 'margin': '5px' }} showTime timeOnly value={selectedTime} onChange={handleTimeChange} onBlur={handleBluredTimeChange} />
      {fetchedData ? <StyledButton label="Proceed to table" onClick={isGeneratingNew ? onClickProceedNewRoute : onClickProceedExistingRoute} /> : <StyledButton label="Proceed to table" disabled />}
      {!isGeneratingNew ? fetchedData ? <StyledButton label="Generate" className="p-button-success" onClick={() => setIsModalOpen(true)} /> : <StyledButton label="Generate" className="p-button-success" disabled /> : null}
      {!isGeneratingNew ? selectedExisting ? <StyledButton onClick={onClickCaseManaging} label="Case editor" /> : <StyledButton label="Case editor" disabled /> : null}
    </>
  );
};

export default PanelSelectors;