import styled from "styled-components";
import { Button } from '../helpers/PrimereactComponents';

export const StyledContainer = styled.div<{ ref: unknown }>`
  display: flex;
  max-height: 80vh;
  margin-top: 5vh;
  margin-left: 160px;
  overflow-x: scroll;
  scroll-behavior: smooth;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  height: 5vh;
  align-items: center;
  right: 2.5vh;
  bottom: 2.5vh;
  padding: 10px;
`;

export const TimelineContainer = styled.div`
  position: fixed;
  left: 7.5%;
  top: calc(5vh - 2px);
  width: 85%;
  height: 10vh;
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;
`

export const CostContainer = styled.div`
  position: absolute;
  width: 140px;
  margin: calc(5vh + 20px) 0 0 10px;
  z-index: 997;
  text-align: center;
`;

export const CostParagraph = styled.p`
  color: ${({ theme }) => theme.primary};
  font-size: 20px;
  margin-bottom: 10px;
`;