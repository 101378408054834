import { sendEventsToBackendAction } from '../ducks/modules/columnsReducer';
import { ColumnInterface, Leg, Stop, routeDetailsInterface } from '../helpers/Interfaces';
import moment from 'moment';

const generateEvents = async (caseId: string | null, routeDetails: routeDetailsInterface, allStations: Stop[], dispatch: any, loggedUser: string, data: ColumnInterface[], assumedPrice: number) => {
  const events = data.map((columnData: ColumnInterface, index: number) => {
    const stationIndex = Math.floor(index / 9);
    const isBetweenStations = index % 9 > 3;
    if (isBetweenStations) {
      const trainsTrip = routeDetails?.trips[0];
      const legsLength = trainsTrip.legs.length;
      const stationsInEachLeg = trainsTrip.legs.map(
        (leg: Leg) => leg.stops.length
      );
      let productIdentifier;
      for (let i = 0; i < legsLength; i++) {
        if (stationIndex < stationsInEachLeg[i] - 1) {
          productIdentifier = trainsTrip.legs[i].productIdentifier;
        }
      }
      if (index === data.length - 2) {
        return {
          ...columnData,
          productIdentifier: Number(productIdentifier),
          timestamp: moment(allStations[allStations.length - 1].arrivalDateTime).add(4, "m").format("YYYY-MM-DDTHH:mm:ss"),
          coordinates: {
            longitude: allStations[allStations.length - 1].longitude,
            latitude: allStations[allStations.length - 1].latitude,
          },
        }
      } else if (index === data.length - 1) {
        return {
          ...columnData,
          productIdentifier: Number(productIdentifier),
          timestamp: moment(allStations[allStations.length - 1].arrivalDateTime).add(5, "m").format("YYYY-MM-DDTHH:mm:ss"),
          coordinates: {
            longitude: allStations[allStations.length - 1].longitude,
            latitude: allStations[allStations.length - 1].latitude,
          },
        };
      } else {
        return {
          ...columnData,
          productIdentifier: Number(productIdentifier),
        };
      }
    } else {
      const currentStation = allStations[stationIndex];
      if (index % 9 === 0) {
        return {
          ...columnData,
          timestamp:
            currentStation.arrivalDateTime ||
            moment(currentStation.departureDateTime)
              .subtract(3, "m")
              .format("YYYY-MM-DDTHH:mm:ss"),
          coordinates: {
            longitude: currentStation.longitude,
            latitude: currentStation.latitude,
          },
          stationId: Number(currentStation.code),
          stationName: currentStation.name,
        };
      } else if (index % 9 === 3) {
        return {
          ...columnData,
          timestamp:
            currentStation.departureDateTime ||
            moment(currentStation.arrivalDateTime)
              .add(3, "m")
              .format("YYYY-MM-DDTHH:mm:ss"),
          coordinates: {
            longitude: currentStation.longitude,
            latitude: currentStation.latitude,
          },
          stationId: Number(currentStation.code),
          stationName: currentStation.name,
        };
      } else {
        return {
          ...columnData,
          coordinates: {
            longitude: currentStation.longitude,
            latitude: currentStation.latitude,
          },
          stationId: Number(currentStation.code),
          stationName: currentStation.name,
        };
}
      }
    });
     const transferStations = allStations.filter((_: any, index: number) => allStations[index]?.name === allStations[index + 1]?.name);
     await dispatch(sendEventsToBackendAction(events, loggedUser, caseId, assumedPrice, transferStations));
 };

export default generateEvents;
