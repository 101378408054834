import { ColumnValuesString } from '../helpers/Interfaces';

const convertToBool = (fieldValue: string | boolean) => fieldValue === "ON" ? true : false;

const convertStringToBooleans = (data: ColumnValuesString[]) => {
  const result = data.map((columnData: ColumnValuesString) => {
    return {
      GPS: columnData.GPS,
      SPEED: columnData.SPEED,
      MOTION_DETECTION: columnData.MOTION_DETECTION,
      GEO_IN: convertToBool(columnData.GEO_IN),
      GEO_OUT: convertToBool(columnData.GEO_OUT),
      SWIPE_IN: convertToBool(columnData.SWIPE_IN),
      SWIPE_OUT: convertToBool(columnData.SWIPE_OUT),
    }
  })
  return result;
};

export default convertStringToBooleans;