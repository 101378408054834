import React, { useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../ducks/modules/rootReducer';
import { setLoggedUserDataAction } from '../ducks/modules/usersReducer';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

const StyledContainer = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 10vh;
  background: ${({ theme }) => theme.primary};
  border: 2px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledAvatarContainer = styled.div`
  height: 100%;
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;

  i {
    font-size: 28px;
  };
`;

const HomeButton = styled.div`
  padding: 10px;
  min-width: 120px;
  cursor: pointer;
  font-size: 24px;
  transition: 0.4s ease-in-out;
  font-weight: bolder;

  :hover {
    font-size: 25px;
  }
`;

const StyledCurrently = styled.div<{ caseEditor: any }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ caseEditor }) => caseEditor ? 'flex-end' : 'space-around'};
  align-items: center;
`;

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loggedUser } = useSelector((state: RootState) => state.users);
  const searchParams = new URLSearchParams(useLocation().search);
  const timestamp = searchParams.get("timestamp");
  const case_editor = searchParams.get("case_editor")

  const onClickLogout = useCallback(() => {
    dispatch(setLoggedUserDataAction())
    history.push('/')
  }, [history, dispatch]);
  const onClickHome = useCallback(() => {
    history.push('/')
  }, [history])

  return (
    <StyledContainer>
      <HomeButton onClick={onClickHome}>TestTool</HomeButton>
      <StyledCurrently caseEditor={case_editor} >
        {timestamp && !case_editor && <p>trips date: <strong>{moment(timestamp).subtract(1, 'h').format("DD-MM-YYYY HH:mm")}</strong></p>}
        <p>token: <strong>{localStorage.getItem("test-tool-token")}</strong></p>
      </StyledCurrently>
      <StyledAvatarContainer>
        {loggedUser &&
          <>
            <StyledIcon onClick={onClickLogout}>
              <i className="pi pi-sign-out" />
            </StyledIcon>
          </>
        }
      </StyledAvatarContainer>
    </StyledContainer>
  );
};

export default Header;
