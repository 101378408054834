import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '../helpers/PrimereactComponents';
import { EventFieldProps } from '../helpers/Interfaces';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventField: React.FC<EventFieldProps> = ({ columnId, buttonsData, setUnlockButtonsKey, unlockButtonsKey, handleButtonChange, forcedValue }) => {
  const [value, setValue] = useState<string | boolean>(forcedValue || buttonsData.default);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (forcedValue !== undefined) {
      setValue(forcedValue);
    }
  }, [forcedValue])

  useEffect(() => {
    if (unlockButtonsKey) {
      if (buttonsData.name === unlockButtonsKey) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(false);
    }
  }, [value, disabled, unlockButtonsKey, buttonsData.name])

  const handleChange = useCallback((value: boolean) => {
    const { name } = buttonsData;
    handleButtonChange({ columnId, name, value });

    if (!unlockButtonsKey && value) {
      setUnlockButtonsKey(buttonsData.name);
    } else if (unlockButtonsKey === buttonsData.name) {
      setUnlockButtonsKey(null);
    }
    setValue(value);
  }, [buttonsData, columnId, handleButtonChange, setUnlockButtonsKey, unlockButtonsKey])

  return (
    <Container>
      <Button
        disabled={disabled}
        className={value ? '' : 'p-button-secondary p-button-outlined'}
        label={buttonsData.options[0].label}
        onClick={() => handleChange(buttonsData.options[0].value)}
      />
      <Button
        disabled={disabled}
        className={!value ? '' : 'p-button-secondary p-button-outlined'}
        label={buttonsData.options[1].label}
        onClick={() => handleChange(buttonsData.options[1].value)}
      />
    </Container>
  );
};

export default EventField;
