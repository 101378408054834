import axios from "axios";
import qs from "qs";
import UserService from "./UserService";

export const APIService = axios.create({
  baseURL: "https://api-test-tool.innokrea.pl/",
  timeout: 15 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: (data) => JSON.stringify(data),
  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: "brackets",
    }),
});

APIService.interceptors.request.use(
  async (config) => {
    const token = UserService.getToken();
    const internalToken = localStorage.getItem("test-tool-token");
    const x_sdk_id = "testtest28";
    const externalEndpoint = localStorage.getItem("test-tool-endpoint");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (internalToken) {
      config.headers.internalToken = internalToken;
    }
    config.headers.sdkId = x_sdk_id;
    if (externalEndpoint) {
      config.headers.externalEndpoint = externalEndpoint;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
