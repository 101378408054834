const weatherRender = (type: string) => {
    switch (type) {
      case "SUNNY":
        return "☀️";
      case "RAINY":
        return "🌧";
      case "CLOWDY":
        return "🌥";
      case "BAD":
        return "🌩";
      default: return "❓"
    }
  }
export default weatherRender;