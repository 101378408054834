import React, { useState, useEffect, useRef } from "react";
import { APIService } from '../services/ApiService';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  initialGenerateStructureAction,
  initialGenerateCheckboxesArrayAction,
  changeRandomGenerateAction,
  sendCaseToBackendAction,
  setJourneyBaseIdAction,
  setGenerateButtonStatusAction,
} from "../ducks/modules/columnsReducer";
import { setUsersCasesAction } from '../ducks/modules/usersReducer';
import { RootState } from "../ducks/modules/rootReducer";

import casesTypes from '../helpers/casesTypes';
import { Leg, ColumnInterface, Stop, PanelFetchedData } from "../helpers/Interfaces";
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';
import { convertStringToBooleans, generateEvents, generateSunnyCase } from '../utils';

import FullRouteTimeline from '../components/Timelines/FullRouteTimeline';
import StationGroup from "../components/Column/StationGroup";
import SingleColumn from "../components/Column/SingleColumn";
import Modal from '../components/Modal';
import { InputText, InputNumber, Dropdown } from '../helpers/PrimereactComponents';
import { StyledContainer, ButtonsContainer, TimelineContainer, StyledButton, CostContainer, CostParagraph } from '../styles/TripStyles';

const Table: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const searchParams = new URLSearchParams(useLocation().search);
  const caseId: null | string = searchParams.get('caseId');
  const firstStationName = searchParams.get("firstStationName");
  const firstStationCode = searchParams.get("firstStationCode");
  const lastStationName = searchParams.get("lastStationName");
  const lastStationCode = searchParams.get("lastStationCode");
  const timestamp = searchParams.get("timestamp");
  const case_editor = searchParams.get("case_editor");

  const { data, journeyBaseId, generateButtonDisabled } = useSelector((state: RootState) => state.columns);
  const { loggedUser } = useSelector((state: RootState) => state.users);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [caseInputName, setCaseInputName] = useState<string>('')
  const [caseType, setCaseType] = useState<string>('');
  const [assumedPrice, setAssumedPrice] = useState<number>(0);
  const [routeDetails, setRouteDetails] = useState<PanelFetchedData | any>({});
  const [allStations, setAllStations] = useState<Stop[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sendRequest = async () => {
      await APIService.post(`journeys/${firstStationCode}/${lastStationCode}`, {
        firstStationName,
        lastStationName,
        timestamp
      }).then(response => {
        dispatch(setJourneyBaseIdAction(response.data.journeyBaseId));
        setRouteDetails(response.data);
        setAllStations(response.data.trips[0].legs.flatMap((leg: Leg) => [
          ...leg.stops.map((stop: Stop) => ({ ...stop, trainId: leg.number })),
        ]).filter((station: Stop) => station.departureDateTime || station.arrivalDateTime))
      });
    }
    sendRequest();
  }, [firstStationCode, firstStationName, lastStationCode, lastStationName, timestamp, dispatch]);

  useEffect(() => {
    if (caseId === 'sunny_case') {
      dispatch(initialGenerateStructureAction(allStations.length * 9 - 3, generateSunnyCase(allStations.length * 9 - 3, routeDetails, allStations)))
    } else if (caseId) {
      const setData = async () => {
        const data: ColumnInterface[] = await APIService.get(`/cases/${caseId}`).then(response => response.data);
        dispatch(initialGenerateStructureAction(allStations.length * 9 - 3, convertStringToBooleans(data)));
      }
      setData();
    }
    dispatch(initialGenerateCheckboxesArrayAction(allStations.length * 9 - 3));
  }, [caseId, dispatch, allStations, routeDetails]);

  useEffect(() => {
    dispatch(setGenerateButtonStatusAction(false));
  }, [dispatch]);

  const handleSaveCase = async (e: React.MouseEvent<HTMLButtonElement>, givenCaseId?: string) => {
    e.preventDefault();
    let newSavedCaseId;
    if (caseInputName && caseType) {
      if (givenCaseId) {
        newSavedCaseId = await dispatch(sendCaseToBackendAction(journeyBaseId, caseInputName, caseType, data, loggedUser, givenCaseId));
      } else {
        newSavedCaseId = await dispatch(sendCaseToBackendAction(journeyBaseId, caseInputName, caseType, data, loggedUser));
      }
      setIsModalOpen(false);
      journeyBaseId && await dispatch(setUsersCasesAction(journeyBaseId));
      case_editor ? history.push(`/trip?firstStationName=${firstStationName}&lastStationName=${lastStationName}&firstStationCode=${firstStationCode}&lastStationCode=${lastStationCode}&timestamp=${timestamp}&caseId=${newSavedCaseId}&case_editor=1`) :
        history.push(`/trip?firstStationName=${firstStationName}&lastStationName=${lastStationName}&firstStationCode=${firstStationCode}&lastStationCode=${lastStationCode}&timestamp=${timestamp}&caseId=${newSavedCaseId}`);
      setCaseInputName('');
      setCaseType('');
    } else {
      toast.warning('Name and type is required!');
    }
  }

  const scrollToStation = (stationIndex: number) => {
    if (containerRef.current) containerRef.current.scrollLeft = stationIndex * 1440;
  };

  const onClickGenerate = () => {
    if (assumedPrice > 0) {
      generateEvents(caseId === 'sunny_case' ? null : caseId, routeDetails, allStations, dispatch, loggedUser, data, assumedPrice);
    } else {
      toast.warning("Type in correct assumed cost")
    }
  }

  return (
    <>
      {!case_editor && <CostContainer>
        <CostParagraph>Assumed cost :</CostParagraph>
        <InputNumber inputStyle={{ width: '140px' }} value={assumedPrice} onValueChange={(e: any) => setAssumedPrice(e.target.value)} mode="currency" currency="EUR" locale="de-DE" />
      </CostContainer>}
      <SingleColumn fixedColumn />
      <StyledContainer ref={containerRef}>
        {allStations.map((station: Stop, index: number) => (
          <StationGroup
            key={index}
            index={index}
            stationData={station}
            total={allStations.length}
          />
        ))}
        <TimelineContainer>
          <FullRouteTimeline data={allStations.map((station: any, index: number) => {
            if (allStations[index]?.name === allStations[index + 1]?.name) {
              return { ...station, changeStation: true }
            } else {
              return station
            }
          })} onClickDot={scrollToStation} />
        </TimelineContainer>
      </StyledContainer>
      <ButtonsContainer>
        <StyledButton
          onClick={() => dispatch(changeRandomGenerateAction())}
          className='p-button-info'
          label='Click to randomize'
        />
        <StyledButton onClick={() => setIsModalOpen(true)} className='p-button-success' label={caseId !== 'sunny_case' ? "Save / Edit case" : "Save case"} />
        {!case_editor ? <StyledButton onClick={onClickGenerate} label="Generate presence data" disabled={generateButtonDisabled} /> : null}
      </ButtonsContainer>
      {isModalOpen &&
        <Modal header="Save journey case" closeModalFunction={() => setIsModalOpen(false)}>
          <div>
            <InputText value={caseInputName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCaseInputName(e.target.value)} placeholder="case name" />
            <Dropdown options={casesTypes} placeholder="case type" value={caseType} onChange={(e) => setCaseType(e.value)} />
            <StyledButton onClick={handleSaveCase} className='p-button-success' label="Save case" />
            {caseId && caseId !== 'sunny_case' && <StyledButton onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleSaveCase(e, caseId)} className='p-button-success' label="Edit case" />}
          </div>
        </Modal>}
    </>
  );
};

export default Table;
