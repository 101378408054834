export const theme = {
  primary: "#2196f3",
  secondary: "#1c80d3",
  white: "#ffffff",
  white25: "#bcbcbc",
  white50: "#e9ecef",
  black: "#000000",
  black50: "#313131",
  black100: "#42526e",
};
