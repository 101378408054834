import { UsersInterface, EndpointsInterface } from './Interfaces';

export const TEMP_ENDPOINT: string = "https://tml-fat-temp-api.fcshosting.scheidt-bachmann.de:30502";
export const QA_ENDPOINT: string = "https://tml-fat-qa-api.fcshosting.scheidt-bachmann.de:30082";
export const DEV_ENDPOINT: string = "https://tml-fat-dev-api.fcshosting.scheidt-bachmann.de:30062";
export const PROD_ENDPOINT: string = "https://tml-production-api.fcshosting.scheidt-bachmann.de";

export const endpoints: EndpointsInterface[] = [
    {
      name: "Temp",
      endpoint: TEMP_ENDPOINT,
    }, {
      name: "QA",
      endpoint: QA_ENDPOINT,
    }, {
      name: "Dev",
      endpoint: DEV_ENDPOINT,
    }, {
      name: "Production",
      endpoint: PROD_ENDPOINT,
    },
  ];

export const users: UsersInterface[] = [
    {
      name: "",
      token: "",
    }, {
      name: "Kathi",
      token: "30fee86c-30d2-4294-a9f3-17ba45f07d54",
    }, {
      name: "Kathi 2",
      token: "53fee86c-30d2-4294-a9f3-17ba45f07d54",
    }, {
      name: "Georg",
      token: "47fee86c-30d2-4294-a9f3-17ba45f07d54",
    }, {
      name: "Test User 1",
      token: "52fee86c-30d2-4294-a9f3-17ba45f07d54",
    }, {
      name: "Test User 2",
      token: "48fee86c-30d2-4294-a9f3-17ba45f07d54",
    }, {
      name: "👨‍💻Dawid (DEV)",
      token: "17fee86c-30d2-4294-a9f3-17ba45f07d54",
    }, {
      name: "👨‍💻Mateusz (DEV)",
      token: "25fee86c-30d2-4294-a9f3-17ba45f07d54"
    }, {
      name: "👨‍💻Paweł (DEV)1",
      token: "18fee86c-30d2-4294-a9f3-17ba45f07d54",
    }, {
      name: "👨‍💻Paweł (DEV)2",
      token: "37fee86c-30d2-4294-a9f3-17ba45f07d54",
    },
  ];