import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ModalProps } from '../helpers/Interfaces';
import { appearWithTranslate } from '../helpers/keyframes';

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 998;
`;



const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 50%;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 18px 0px ${({ theme }) => theme.black50};
  animation: ${appearWithTranslate} 0.4s ease-in-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f51505;
  color: ${({ theme }) => theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: 0.2s ease-in-out;

  :hover {
    background: #c71100;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  background: ${({ theme }) => theme.primary};
  padding: 0px 20px;
  line-height: 50px;
  text-align: center;
  font-size: 36px;
  color: ${({ theme }) => theme.white};
`;

const Content = styled.div`
  padding: 30px 20px 20px 20px;
`;

const Modal: React.FC<ModalProps> = ({ children, header, closeModalFunction }) => {

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        closeModalFunction();
      }
    });

    return () => {
      document.removeEventListener('keydown', closeModalFunction);
    };
  }, [closeModalFunction]);

  return (
    <>
      <Overlay onClick={closeModalFunction} />
      <Container>
        <Header>
          {header}
          <CloseButton onClick={closeModalFunction}>x</CloseButton>
        </Header>
        <Content>
          {children}
        </Content>
      </Container>
    </>
  );
};

export default Modal;
